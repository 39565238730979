import { useState } from 'react';
import { Accordion, Button, Group, Text} from '@mantine/core';
import { saveDefaultFieldGroups } from '../../data/dataApi';
import DefaultFieldGroup from './DefaultFieldGroup';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';
import { useUserState } from './UserStateContext';

const DefaultFieldGroups = ({ groups, onChange, type }) => {
    const [error, setError] = useState(null);
    const initialGroup = {
        name: "",
        fields: [],
        newGroup: true,
        edited: true
    };

    const { globalEditingMode } = useUserState();
    const [loading, setLoading] = useState(false);

    const validateFieldName = (fields) => {
        const fieldNames = fields.map(field => field.name);
        const uniqueFieldNames = new Set(fieldNames);
        return fieldNames.length !== uniqueFieldNames.size || fieldNames.some(name => !name);
    };
    
    const handleSave = async () => {
        setError(null);
    
        if(groups.some(group => validateFieldName(group.fields))) {
            setError("Please make sure all field names are unique and not empty");
            return;
        }

        setLoading(true);
        const response = await saveDefaultFieldGroups(groups);

        if(response.success) {
            onChange(prevState => {
                const newGroups = [...prevState];
                for(const group of newGroups) {
                    for(const field of group.fields) {
                        field.newField = false;
                        field.originalName = field.name;
                    }
                }
                return newGroups;
            });
            showSuccessNotification("Field groups saved successfully");
        } else {
            showUnexpectedErrorNotification();
        }
        setLoading(false);
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <p>Groups</p>

            <Accordion mb="20" variant="contained">
                {
                    groups.map((group, groupIndex) => (
                        <DefaultFieldGroup group={group} position={groupIndex} key={groupIndex} onGroupUpdate={onChange} type={type} />
                    ))
                }
            </Accordion>

            <Button variant="light" onClick={() => onChange(prevState => ([...prevState, initialGroup]))} mb="30" disabled={globalEditingMode}>Add Group</Button>

            <Group justify="right" mt="auto">
                <Text color="red" size="sm">{error}</Text>
                <Button onClick={handleSave} variant="outline" loading={loading}>
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default DefaultFieldGroups;