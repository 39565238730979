import { Text, Table, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { saveClientContacts } from '../../data/dataApi';
import { showSuccessNotification, showUnexpectedErrorNotification } from "../helpers";
import ContactItem from "./ContactItem";

const ClientContacts = ({ contacts, onChange, id, className }) => {
    const handleContactChange = (index, property, value) => {
        onChange(prevState => {
            return {
                ...prevState,
                contacts: prevState.contacts.map((contact, i) => {
                    if (i === index) {
                        return {
                            ...contact,
                            [property]: value
                        };
                    }
                    return contact;
                })
            };
        });
    };

    const handleAddContact = () => {
        onChange(prevState => {
            return {
                ...prevState,
                contacts: [
                    ...prevState.contacts,
                    {
                        contact_name: "",
                        contact_email: ""
                    }
                ]
            };
        });
    };

    const handleDeleteContact = async index => {
        const response = await saveClientContacts(id, contacts.filter((contact, i) => i !== index));

        if (response.success) {
            onChange(prevState => {
                return {
                    ...prevState,
                    contacts: prevState.contacts.filter((contact, i) => i !== index)
                };
            });

            showSuccessNotification("Contact deleted successfully.");
        } else {
            showUnexpectedErrorNotification();
        }
    };

    return (
        <div className={className}>
            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contacts</Text>
            <Table className="tif-mb-20" verticalSpacing="sm">
                <Table.Thead key="person-1">
                    <Table.Tr>
                        <Table.Th>Name</Table.Th>
                        <Table.Th>Email</Table.Th>
                        <Table.Th></Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {
                        contacts.map(({ contact_name, contact_email }, index) => (
                            <ContactItem clientId={id} contactName={contact_name} contactEmail={contact_email} onChange={handleContactChange} position={index} contacts={contacts} onDelete={handleDeleteContact} key={index} />
                        ))
                    }
                </Table.Tbody>
            </Table>
            <Button fullWidth variant="default" leftSection={<IconPlus size={12} />} size="xs" onClick={handleAddContact}>Add Contact</Button>
        </div>
    );
};

export default ClientContacts;