import { useEffect, useState } from 'react';
import { Modal, Group, Avatar, Text, Box, Divider } from '@mantine/core';
import { roles, userSettingsLinks } from '../helpers';
import UserGeneralSettings from './UserGeneralSettings';
import UserTimeSettings from './UserTimeSettings';
import UserTimeOffSettings from './UserTimeOffSettings';
import { getUserSettings } from '../../data/dataApi';
import UserExistingTimeOffSettings from './UserExistingTimeOffSettings';

const EditUserModal = ({ opened, close, user, onConfirmationModalOpen, onDeleteTimeOff, onModalUserUpdate, settingsPage }) => {
    const initialTab = userSettingsLinks[0];

    const [activeTab, setActiveTab] = useState(initialTab);
    const [dailyHoursLogged, setDailyHoursLogged] = useState(0);
    const [dailyBillableGoal, setDailyBillableGoal] = useState(0);
    const [PTOStartDate, setPTOStartDate] = useState(null);
    const [PTOEndDate, setPTOEndDate] = useState(null);

    const items = userSettingsLinks.map((item) => (
        <Box
            component="a"
            onClick={() => setActiveTab(item)}
            key={item.label}
            className={`link position-relative ${activeTab.link === item.link && "linkActive"}${ item.order > 1 ? " sublink" : "" }`}
            style={{ paddingLeft: `calc(${item?.order || 1} * var(--mantine-spacing-md))` }}
            mb="5px"
        >
            {item.label}
        </Box>
    ));

    const handleUserTimeOffSaveSuccess = () => {
        setPTOStartDate(null);
        setPTOEndDate(null);
        setActiveTab(userSettingsLinks[3]);
    };

    useEffect(() => {
        if (user?.id) {
            (async () => {
                const response = await getUserSettings(user.id);

                if (response.success) {
                    const data = response.data.rows[0];

                    setDailyHoursLogged(data.daily_hours_logged);
                    setDailyBillableGoal(data.daily_billable_goal);
                }
            })();
        }
    }, [user?.id]);

    useEffect(() => {
        opened && setActiveTab(initialTab);
    }, [opened]);

    useEffect(() => {
        settingsPage && setActiveTab(settingsPage);
    }, [settingsPage]);

    return (
        <Modal.Root opened={opened} onClose={close} className="user-settings-modal" size="xl">
            <Modal.Overlay blur="3" />
            <Modal.Content>
                <Group gap="25px" align="stretch" className="h-100">
                    <div className="user-settings-tabs">
                        {
                            user ?
                                <Group gap="sm" className="avatar-wrapper flex-nowrap tif-mb-20">
                                    <Avatar src={user.avatar_url} size="md" />
                                    <div>
                                        <Text fz="sm" fw={500}>{user.name}</Text>
                                        <Text c="dimmed" fz="xs">
                                            {roles[user.role]}
                                        </Text>
                                    </div>
                                </Group>
                                :
                                <></>
                        }
                        <Divider mb="20px" />
                        <Box pl="16px">
                            {items}
                        </Box>
                    </div>
                    <Modal.Body className="d-flex flex-column flex-grow-1 tif-pl-0">
                        <Modal.Header className="tif-px-0">
                            <Modal.Title>
                                { activeTab.label}
                            </Modal.Title>
                            <Modal.CloseButton ml="0" />
                        </Modal.Header>
                        {
                            activeTab.link === "#general" ?
                                <UserGeneralSettings user={user} />
                            :
                                <></>
                        }
                        {
                            activeTab.link === "#time" ?
                                <UserTimeSettings user={user} data={{ dailyHoursLogged, setDailyHoursLogged, dailyBillableGoal, setDailyBillableGoal }}  />
                            :
                                <></>
                        }
                        {
                            activeTab.link === "#time-off" ?
                            <UserTimeOffSettings user={user} data={{ PTOStartDate, setPTOStartDate, PTOEndDate, setPTOEndDate, dailyBillableGoal }} onSuccessChangeTab={() => handleUserTimeOffSaveSuccess()} onModalUserUpdate={onModalUserUpdate} />
                            :
                                <></>
                        }
                        {
                            activeTab.link === "#existing-time-off" ?
                                <UserExistingTimeOffSettings user={user} onDeleteTimeOff={onDeleteTimeOff} onConfirmationModalOpen={onConfirmationModalOpen} />
                            :
                                <></>
                        }
                    </Modal.Body>
                </Group>
            </Modal.Content>
        </Modal.Root>
    );
};

export default EditUserModal;