import Sidebar from "./Sidebar";

const ContentWrapper = ({ children, className }) => {
    return (
        <div id="page">
            <div id="content-wrapper" className={ className }>
                <Sidebar />
                <div id="main-content" className="flex-grow-1">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ContentWrapper;