import { Form, Button } from "react-bootstrap";
import { useState } from "react";

const PasswordField = ({ onChange, error, placeholder }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return(
        <div className="password-wrapper">
            <Form.Control type={ passwordVisible ? "text" : "password" } onChange={e => onChange(e.target.value)} className={ error ? "has-error" : "" } placeholder={ placeholder ? placeholder : "•••••••••••" } />
            {/* <Button className="password-visibility-btn" onClick={() => setPasswordVisible(prevState => !prevState)}><img src={ passwordVisible ? EyeOffIcon : EyeIcon } alt="Password visibility icon" /></Button> */}
        </div>
    );
};

export default PasswordField;