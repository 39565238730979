import React, { useEffect, useState } from 'react';
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';
import { getUserAlerts, logoutUser, markAlertComplete } from '../../data/dataApi';
import {
    Tooltip,
    UnstyledButton,
    Stack,
    rem,
    Menu,
    ActionIcon,
    useMantineColorScheme,
    useComputedColorScheme,
    Drawer,
    Indicator,
    Text,
    Notification,
    Button,
    ButtonGroup
} from '@mantine/core';
import { useUserState } from './UserStateContext';
import { useDisclosure } from '@mantine/hooks';

// Images
import LogoLight from "../../images/logo-light.svg";
import LogoDark from "../../images/logo-dark.svg";

// Icons
import {
    IconChartBar,
    IconHome,
    IconLogout,
    IconSettings,
    IconUserCog,
    IconUsersGroup,
    IconSun,
    IconMoon,
    IconBell,
    IconCircleDashedCheck,
    IconCheck
} from '@tabler/icons-react';
import ClientsSettingsModal from './ClientsSettingsModal';

const Sidebar = () => {
    const { resetAllStatistics, userRole, alerts, setAlerts } = useUserState();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [clientsSettingsModalOpen, { open: openClientsSettingsModal, close: closeClientsSettingsModal }] = useDisclosure(false);
    const [alertsDrawerOpened, { open: openAlertsDrawer, close: closeAlertsDrawer }] = useDisclosure(false);

    const { setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    const data = [
        { link: '/home', label: 'Home', icon: IconHome },
        { link: '/team-dashboard', label: 'Team Dashboard', icon: IconChartBar, nonAdmin: true },
        { link: '/user-accounts', label: 'User Accounts', icon: IconUserCog, admin: true },
        {
            link: '/clients', label: 'Clients', icon: IconUsersGroup, menu: {
                title: "Clients",
                links: [
                    {
                        link: '/clients',
                        label: 'All Clients',
                        icon: <IconUsersGroup style={{ width: 14, height: 14 }} />
                    },
                    {
                        label: 'Settings',
                        icon: <IconSettings style={{ width: 14, height: 14 }} />,
                        modal: 'clients-settings',
                        onClick: openClientsSettingsModal,
                        admin: true
                    }
                ]
            },
        },
    ];

    const navigate = useNavigate();

    const handleLogout = async () => {
        const response = await logoutUser();

        resetAllStatistics();

        response.success && navigate("/");
    };

    const NavbarLink = ({ icon: Icon, label, active, link, onClick }) => {
        return (
            <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
                {
                    link ?
                        <Link to={link} onClick={onClick} className="link" data-active={active || undefined}>
                            <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                        </Link>
                        :
                        <UnstyledButton onClick={onClick} className="link" data-active={active || undefined}>
                            <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                        </UnstyledButton>
                }

            </Tooltip>
        );
    };

    const links = data.map(link => {
        const NavLinkItem = (
            <NavbarLink
                {...link}
                key={link.label}
                active={activeLink.includes(link.link)}
                onClick={() => setActiveLink(link.link)}
            />
        );

        if ((link.nonAdmin && userRole === "ops") || (link.admin && userRole !== "ops")) {
            return null;
        } else if (link.menu) {
            return (
                <Menu key={link.label} position="right-start">
                    <Menu.Target>
                        <Tooltip label={link.label} position="right" transitionProps={{ duration: 0 }}>
                            <UnstyledButton className="link">
                                <IconUsersGroup style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                            </UnstyledButton>
                        </Tooltip>
                    </Menu.Target>
                    <Menu.Dropdown className="sidebar-menu-dropdown">
                        <Menu.Label>{link.label}</Menu.Label>
                        {
                            link.menu.links.map((sublink, index) => (
                                sublink.link ?
                                    <Menu.Item className="link" component={Link} to={sublink.link} leftSection={sublink.icon} data-active={activeLink.includes(sublink.link) || undefined} onClick={() => setActiveLink(sublink.link)} key={index}>{sublink.label}</Menu.Item>
                                    :
                                        sublink.modal ?
                                            <Menu.Item component={UnstyledButton} className="link" leftSection={sublink.icon} onClick={sublink.onClick} key={index}>{sublink.label}</Menu.Item>
                                        :
                                            <></>
                            ))
                        }
                    </Menu.Dropdown>
                </Menu>
            );
        }

        return NavLinkItem;
    });

    const handleMarkAlertComplete = async id => {
        const response = await markAlertComplete(id);

        if(response.success) {
            setAlerts(alerts.filter(alert => alert.id !== id));
        }
    };

    useEffect(() => {
        (async () => { 
            if(alertsDrawerOpened) {
                const response = await getUserAlerts();

                if(response.success) {
                    setAlerts(response.data.rows);
                }
            }
        })();
    }, [alertsDrawerOpened]);

    return (
        <>
            <nav className="sidebar">
                <Stack className="sidebar-header tif-pt-10" align="center" gap={0}>
                    <Link to="/home">
                        {
                            computedColorScheme === 'dark' ?
                                <img src={LogoLight} alt="Think It First logo" />
                            :
                                <img src={LogoDark} alt="Think It First logo" />
                        }
                    </Link>
                </Stack>

                <div className="sidebar-main">
                    <Stack justify="center" gap={0}>
                        {links}
                    </Stack>
                </div>

                <Stack justify="center" gap={5}>
                    <ActionIcon
                        onClick={openAlertsDrawer}
                        variant="subtle"
                        size="50px"
                        aria-label="Open/Close alerts drawer"
                    >
                        <Indicator inline processing color="red" size={12} position="bottom-end" withBorder disabled={!alerts.length}>
                            <IconBell style={{ width: "20px", height: "20px" }} stroke={1.5} />
                        </Indicator>
                    </ActionIcon>
                    <ActionIcon
                        onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
                        variant="default"
                        size="50px"
                        aria-label="Toggle color scheme"
                    >
                        {
                            computedColorScheme === 'dark' ?
                                <IconSun style={{ width: "20px", height: "20px" }} stroke={1.5} />
                            :
                                <IconMoon style={{ width: "20px", height: "20px" }} stroke={1.5} />
                        }
                    </ActionIcon>
                    <NavbarLink icon={IconLogout} label="Logout" onClick={handleLogout} />
                </Stack>
            </nav>
            <ClientsSettingsModal opened={clientsSettingsModalOpen} close={closeClientsSettingsModal} />
            <Drawer opened={alertsDrawerOpened} onClose={closeAlertsDrawer} title="Alerts">
                {
                    alerts.length ?
                        <Stack>
                            {
                                alerts.map(({ id, title, message }, index) => (
                                    <Notification title={title} withCloseButton={false} withBorder key={index}>
                                        {message.split('\n').map((line, i) => (
                                            <React.Fragment key={i}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                        <ButtonGroup mt="10px">
                                            <Button color="teal" leftSection={<IconCheck size="14px" />} 
                                        size="compact-xs" onClick={() => handleMarkAlertComplete(id)}>Mark as complete</Button>
                                        </ButtonGroup>
                                    </Notification>
                                ))
                            }
                        </Stack>
                    :
                        <Stack align="center" py="50px">
                            <IconCircleDashedCheck color="gray" style={{ width: "100px", height: "100px" }} stroke={1.5} />
                            <Text>No alerts</Text>
                        </Stack>
                }
            </Drawer>
        </>
    );
};

export default Sidebar;
