import {
    Group,
    SimpleGrid,
    Text,
    Progress,
    Paper,
    NumberFormatter,
} from '@mantine/core';
import { IconDeviceAnalytics } from '@tabler/icons-react';

const ProgressBarCard = ({ segments, descriptions, total, cols, ...props }) => {
    return(
        <SimpleGrid cols={{ base: 1, md: 1 }} {...props}>
            <Paper withBorder p="md" radius="md" className="ticket-count-stats">
                <Group align="flex-start">
                    <div>
                        <Group justify="space-between">
                            <Group align="flex-end" gap="xs">
                                <Text fz="xl" fw={700}>
                                    <NumberFormatter thousandSeparator value={total} />
                                </Text>
                            </Group>
                            <IconDeviceAnalytics size="1.5rem" className="icon" stroke={1.5} />
                        </Group>

                        <Text c="dimmed" fz="sm" className="text-uppercase">
                            Total Tickets
                        </Text>
                    </div>
                    <div className="flex-grow-1">
                        <Progress.Root size={34} classNames={{ label: "progressLabel" }}>
                            {segments}
                        </Progress.Root>
                        <SimpleGrid cols={cols || ({ base: 1, md: 3 })} mt="xl">
                            {descriptions}
                        </SimpleGrid>
                    </div>
                </Group>
            </Paper>
        </SimpleGrid>
    );
};

export default ProgressBarCard;