import { useState } from 'react';
import { NumberInput, Button, Group, Table } from '@mantine/core';
import { updateUserTimeSettings } from '../../data/dataApi';
import { getNetworkDays, showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';
import { useUserState } from './UserStateContext';

const UserTimeSettings = ({ user, data }) => {
    const { resetAllStatistics } = useUserState();
    const { dailyHoursLogged, setDailyHoursLogged, dailyBillableGoal, setDailyBillableGoal } = data;
    const [dailyHoursLoggedError, setDailyHoursLoggedError] = useState("");
    const [dailyBillableGoalError, setDailyBillableGoalError] = useState("");

    const maxHoursAvailable = getNetworkDays() * dailyBillableGoal;

    const handleSave = async () => {
        setDailyBillableGoalError("");
        setDailyHoursLoggedError("");

        const errorMessage = "Please enter a valid number.";

        if (dailyHoursLogged.length === 0 || isNaN(dailyHoursLogged) || dailyHoursLogged < 0) {
            setDailyHoursLoggedError(errorMessage);
            return;
        } else if (dailyBillableGoal.length === 0 || isNaN(dailyBillableGoal) || dailyBillableGoal < 0) {
            setDailyBillableGoalError(errorMessage);
            return;
        }

        const response = await updateUserTimeSettings(user.id, {
            dailyHoursLogged,
            dailyBillableGoal,
        });

        if (response.success) {
            resetAllStatistics();
            showSuccessNotification("User time settings saved successfully.");
        } else {
            showUnexpectedErrorNotification("There was an error saving the user time settings. Please try again.");
        }
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <Table className="tif-mb-20">
                <Table.Thead>
                    <Table.Tr className="border-0">
                        <Table.Th className="tif-px-0">Net Working Days</Table.Th>
                        <Table.Td className="text-end tif-px-0">{getNetworkDays()}</Table.Td>
                    </Table.Tr>
                </Table.Thead>
            </Table>

            <NumberInput
                label="Daily Hours Logged"
                value={dailyHoursLogged}
                onChange={setDailyHoursLogged}
                allowNegative={false}
                error={dailyHoursLoggedError}
                className="tif-mb-10"
            />

            <NumberInput
                label="Daily Billable Goal"
                value={dailyBillableGoal}
                onChange={setDailyBillableGoal}
                allowNegative={false}
                error={dailyBillableGoalError}
                className="tif-mb-20"
            />

            <Table className="tif-mb-20">
                <Table.Thead>
                    <Table.Tr className="border-0">
                        <Table.Th className="tif-px-0">Max Hours Available This Month</Table.Th>
                        <Table.Td className="text-end tif-px-0">{maxHoursAvailable}</Table.Td>
                    </Table.Tr>
                </Table.Thead>
            </Table>

            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline">
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default UserTimeSettings;