import {
    Form,
    Button
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { register } from "../data/dataApi";
import PasswordField from '../views/components/PasswordField';
import LoginScreen from '../views/components/LoginScreen';

const Register = () => {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    const [confirmPasswordError, setConfirmPasswordError] = useState();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();

    const { key } = useParams();

    const handleRegister = async () => {
        setPasswordError("");
        setConfirmPasswordError("");
        setError("");

        if(!password) {
            setPasswordError("Please enter a password.");
        } else if(!/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)) {
            setPasswordError("Password must be at least 8 characters long and include 1 capital letter and 1 number.");
        } else if(!confirmPassword || password !== confirmPassword) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            const response = await register(key, password);

            if(response.error) {
                setError(response.error.message);
            } else if(response.success && response.data.rowCount === 1) {
                setSuccess(true);
            }
        }
    };

    return (
        <LoginScreen>
            {
                !success ?
                    <>
                        <h1 className="tif-mb-md-15">Register</h1>
                        <p className="tif-mb-md-65">Choose a password for your account</p>

                        <Form className="primary-form">
                            <Form.Group className="form-group tif-mb-sm-25 tif-mb-15" controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <PasswordField onChange={ setPassword } error={ passwordError } />
                                <Form.Text className="text-muted tif-font-sm password-requirements-label tif-mt-sm-10 tif-mt-5">Your password must be at least 8 characters long and include 1 capital letter and 1 number.</Form.Text>
                                { passwordError && <p className="error-text">{ passwordError }</p> }
                            </Form.Group>

                            <Form.Group className="form-group tif-mb-sm-25 tif-mb-15" controlId="formConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <PasswordField onChange={ setConfirmPassword } error={ confirmPasswordError } />
                                { confirmPasswordError && <p className="error-text">{ confirmPasswordError }</p> }
                            </Form.Group>

                            { error && <p className="error-text">{ error }</p> }
                            <Button className="primary-btn tif-mb-15" onClick={ handleRegister }>Register</Button>
                            <Link to="/" className="secondary-btn">Back to Login</Link>
                        </Form>
                    </>
                :
                    <>
                        <h1 className="tif-h2 tif-mb-md-15">Registration successful</h1>
                        <p className="tif-mb-md-55 tif-mb-25">You can now log in to your account.</p>

                        <Link to="/" className="primary-btn">Login</Link>
                    </>
            }
        </LoginScreen>
    );
};

export default Register;