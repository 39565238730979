import { useEffect, useState } from "react";
import { PasswordInput, Switch, Textarea, TextInput, NumberInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { toIdentifier } from "../helpers";
import { IconCheck, IconX } from "@tabler/icons-react";

const Field = ({ field, value, onChange }) => {
    const [fieldValue, setFieldValue] = useState(field.type === "Yes/No" ? (value === "true") : value);

    const textFields = ["Text", "Email", "Phone", "URL"];
    const textareaFields = ["Address"];
    const passwordFields = ["Password"];
    const datePickerFields = ["Date Picker"];
    const numberFields = ["Number"];
    const yesNoFields = ["Yes/No"];

    useEffect(() => {
        if (value !== fieldValue) {
            onChange(prevState => {
                const fieldName = toIdentifier(field.name);
                if (prevState.some((item) => item.name === fieldName)) {
                    return prevState.map((item) => {

                        if (item.name === fieldName) {
                            return { id: item?.id, name: fieldName, value: fieldValue };
                        }
                        return item;
                    });
                } else {
                    return [...prevState, { name: fieldName, value: fieldValue }];
                }
            });
        }
    }, [fieldValue]);

    if (textFields.includes(field.type)) {
        return (
            <TextInput
                label={field.name}
                defaultValue={value}
                value={fieldValue}
                onChange={e => setFieldValue(e.target.value)}
                className="tif-mb-20"
            />
        );
    } else if (textareaFields.includes(field.type)) {
        return (
            <Textarea
                label={field.name}
                defaultValue={value}
                value={fieldValue}
                onChange={e => setFieldValue(e.target.value)}
                className="tif-mb-20"
                resize="vertical"
            />
        );
    } else if (passwordFields.includes(field.type)) {
        return (
            <PasswordInput
                label={field.name}
                defaultValue={value}
                value={fieldValue}
                onChange={e => setFieldValue(e.target.value)}
                className="tif-mb-20"
            />
        );
    } else if (datePickerFields.includes(field.type)) {
        return (
            <DateInput
                label={field.name}
                defaultValue={new Date(value)}
                value={new Date(fieldValue)}
                onChange={setFieldValue}
                className="tif-mb-20"
            />
        );
    } else if (numberFields.includes(field.type)) {
        return (
            <NumberInput
                label={field.name}
                defaultValue={value}
                value={fieldValue}
                onChange={setFieldValue}
                className="tif-mb-20"
            />
        );
    } else if (yesNoFields.includes(field.type)) {
        return (
            <Switch
                label={field.name}
                labelPosition="left"
                thumbIcon={
                    (fieldValue === "true" || fieldValue === true) ? (
                        <IconCheck
                            style={{ width: "12px", height: "12px" }}
                            color="teal"
                            stroke={3}
                        />
                    ) : (
                        <IconX
                            style={{ width: "12px", height: "12px" }}
                            color="red"
                            stroke={3}
                        />
                    )
                }
                defaultValue={value === "false" ? false : true}
                checked={fieldValue}
                onChange={e => setFieldValue(e.currentTarget.checked)}
                className="tif-mb-20"
            />
        );
    }

    return;
};

export default Field;