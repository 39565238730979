import { useState } from 'react';
import { Table, Group, ActionIcon, UnstyledButton, Text, Center } from '@mantine/core';
import { IconChevronUp, IconChevronDown, IconTrash, IconSelector } from '@tabler/icons-react';

const UserExistingTimeOffSettings = ({ user, onConfirmationModalOpen, onDeleteTimeOff }) => {
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("");

    const ThSortIcon = ({ by }) => {
        if (sortBy === by) {
            if (sortOrder === "asc") {
                return <IconChevronUp style={{ width: "1rem", height: "1rem" }} stroke={1.5} />;
            } else if (sortOrder === "desc") {
                return <IconChevronDown style={{ width: "1rem", height: "1rem" }} stroke={1.5} />;
            }
        }

        return <IconSelector style={{ width: "1rem", height: "1rem" }} stroke={1.5} />;
    };

    const handleSortClick = by => {
        setSortOrder((sortOrder === "asc" || sortBy !== by) ? "desc" : "asc");
        setSortBy(by);
    };

    const Th = ({ label, sortByCol }) =>
        <Table.Th>
            <UnstyledButton onClick={() => handleSortClick(sortByCol)} className="control">
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {label}
                    </Text>
                    <Center className="icon">
                        <ThSortIcon by={sortByCol} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>;

    const columns = [
        { label: "Date", sortByCol: "date" },
        { label: "Hours", sortByCol: "hours" },
    ];

    const sortTable = (table, by, order) => table.sort((a, b) => {
        if (by === "date") {
            const [monthA, dayA, yearA] = a.date.split("/");
            const [monthB, dayB, yearB] = b.date.split("/");
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);

            return order === "asc" ? dateB - dateA : dateA - dateB;
        } else if (by === "hours") {
            return order === "asc" ? b.hours - a.hours : a.hours - b.hours;
        }
    });

    const handleDeleteClick = date => {
        onConfirmationModalOpen(true);
        onDeleteTimeOff(date);
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            {
                user.pto?.length ?
                    <Table className="sortable-table">
                        <Table.Thead>
                            <Table.Tr>
                                {
                                    columns.map(({ label, sortByCol }) => (
                                        <Th key={sortByCol} label={label} sortByCol={sortByCol} />
                                    ))
                                }
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {
                                sortTable(user.pto, sortBy, sortOrder).map(({ date, hours }, index) =>
                                    <Table.Tr key={index}>
                                        <Table.Td>
                                            {date}
                                        </Table.Td>
                                        <Table.Td>{hours}</Table.Td>
                                        <Table.Td>
                                            <Group gap={0} justify="flex-end">
                                                <ActionIcon variant="subtle" color="red" onClick={() => handleDeleteClick(date)}>
                                                    <IconTrash style={{ width: "1rem", height: "1rem" }} stroke={1.5} />
                                                </ActionIcon>
                                            </Group>
                                        </Table.Td>
                                    </Table.Tr>
                                )
                            }
                        </Table.Tbody>
                    </Table>
                    :
                    <></>
            }
        </div>
    );
};

export default UserExistingTimeOffSettings;