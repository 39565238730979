import { useUserState } from '../views/components/UserStateContext';
import ContentWrapper from '../views/components/ContentWrapper';
import Statistics from '../views/components/Statistics';
import IndividualStats from '../views/components/IndividualStats';
import InitialSetupModal from '../views/components/InitialSetupModal';

const AdminHome = () => {
    const { hasTempoTokens, hasJiraApiToken, excludeAccounts, setExcludeAccounts } = useUserState();

    return (
        <ContentWrapper className="scrollable">
            <div className="wrapper">
                {
                    (hasTempoTokens && hasJiraApiToken) ?
                        <>
                            <Statistics excludeAccounts={excludeAccounts} onExcludeAccountsChange={setExcludeAccounts} />
                            <IndividualStats excludeAccounts={excludeAccounts} />
                        </>
                    :
                        (hasTempoTokens !== null && hasJiraApiToken !== null) ?
                            <InitialSetupModal />
                        :
                            <></>
                }
            </div>
        </ContentWrapper>
    );
};

export default AdminHome;