import {
  TextInput,
  PasswordInput,
  Button,
  Text,
  Stack
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  validateEmail,
  validatePassword
} from "../views/helpers";
import { loginUser } from "../data/dataApi";
import { useNavigate } from "react-router-dom";
import LoginScreen from '../views/components/LoginScreen';

const Login = () => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [unexpectedError, setUnexpectedError] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const authPreCheck = async () => {
    setLoading(true);
    setEmailError(null);
    setPasswordError(null);

    if (validateEmail(email, setEmailError) === "success" && validatePassword(password, setPasswordError) === "success") {
      const response = await loginUser(email, password);

      if (response.success) {
        navigate("/home", { replace: true });
        return;
      }

      if (response.error.field === "email") {
        setEmailError(response.error.message);
      }

      if (response.error.field === "password") {
        setPasswordError(response.error.message);
      }

      if (!response.error.field) {
        setUnexpectedError(response.error.message);
      }
    }

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      authPreCheck();
    }
  };

  return (
    <LoginScreen>
      <Stack justify="center" gap="0" className="h-100">
        <h1 className="tif-mb-50">Login</h1>

        <TextInput
          type="email"
          label="Email address"
          placeholder="hello@gmail.com"
          size="md"
          labelProps={{ mb: 10 }}
          onChange={e => setEmail(e.target.value)}
          error={emailError}
          onKeyDown={handleKeyDown}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          mt="md"
          mb="5"
          size="md"
          labelProps={{ mb: 10 }}
          onChange={e => setPassword(e.target.value)}
          error={passwordError}
          onKeyDown={handleKeyDown}
        />
        <Text fz="sm">
          Forgot Password?{' '}
          <Link to="/reset-password">
            Reset here
          </Link>
        </Text>

        {unexpectedError && <p className="error-text">{unexpectedError}</p>}

        <Button fullWidth mt="xl" size="md" onClick={authPreCheck} loading={loading}>
          Login
        </Button>
      </Stack>
    </LoginScreen>
  );
};

export default Login;