import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTempoToken, reAuthenticateUser, saveTempoAccessToken } from '../data/dataApi';
import { useUserState } from '../views/components/UserStateContext';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const { setHasTempoTokens } = useUserState();

  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState();
  const [unauthorized, setUnauthorized] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const authorizationCode = query.get('code');
    let accessToken = query.get('access_token');
    let refreshToken = query.get('refresh_token');

    (async () => {
      let tempoCreds;

      if(!accessToken) {
        tempoCreds = await getTempoToken(authorizationCode);
        accessToken = tempoCreds.data.access_token;
        refreshToken = tempoCreds.data.refresh_token;

        if(!tempoCreds.success || !accessToken) {
          setUnauthorized(true);
        }
      }

      
      if(process.env.NODE_ENV === 'development' && accessToken && window.location.origin === process.env.REACT_APP_NGROK_URL) {
        window.location.href = `http://localhost:3000/tempo-auth?code=${ authorizationCode }&access_token=${accessToken}&refresh_token=${refreshToken}`;
      } else {
        setAuthenticated(true);
        const updateResponse = await saveTempoAccessToken({ accessToken, refreshToken });
        const reAuthResponse = await reAuthenticateUser();

        if(updateResponse.success && reAuthResponse.success) {
          setHasTempoTokens(true);
          setTimeout(() => {
            navigate('/home');
          }, 3000);
        }
      }

      setLoading(false);
    })();
  }, []);

  return (
    <div>
      { loading ? <h2>Authorization in progress...</h2> : <></> }
      { authenticated ? <h2>Authorization successful! You will be redirected...</h2> : <></> }
      { unauthorized ? <h2>Authentication failed. Please try again.</h2> : <></> }
    </div>
  );
};

export default AuthCallback;
