import { useEffect, useRef, useState } from 'react';
import ContentWrapper from '../../views/components/ContentWrapper';
import { Breadcrumbs, Text, ActionIcon, Group, Paper, Accordion, Avatar, Anchor, SimpleGrid, Skeleton, Progress, Box, Select } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getBilledTimeChartValues, getClient, getClientDetails, getClientTotalBilled } from '../../data/dataApi';
import EditableField from '../../views/components/EditableField';
import { projectTypes, reformatClientDetails, toIdentifier, hosts, listTickets } from '../../views/helpers';
import ClientSettingsModal from '../../views/components/ClientSettingsModal';
import { useDisclosure } from '@mantine/hooks';
import { IconListSearch, IconSettings, IconCoin, IconReceipt2, IconCalendarDollar, IconCalendarClock, IconCalendarUp, IconClockStop, IconClockPlay, IconClockQuestion, IconClockSearch, IconAlertTriangle, IconFlame, IconCircleCheck, IconCalendarMonth } from '@tabler/icons-react';
import Element from '../../views/components/Element';
import { useUserState } from '../../views/components/UserStateContext';
import Card from '../../views/components/Card';
import ApexCharts from 'apexcharts';
import ClientContacts from '../../views/components/ClientContacts';
import ProgressBarCard from '../../views/components/ProgressBarCard';

const SingleClient = () => {
    const { clientDetails, setClientDetails } = useUserState();
    const [client, setClient] = useState();
    const [clientTotalBilled, setClientTotalBilled] = useState(0);
    const [clientTotalBudget, setClientTotalBudget] = useState(0);
    const [clientMonthlyBudget, setClientMonthlyBudget] = useState(0);
    const [clientTicketCounts, setClientTicketCounts] = useState();
    const [clientTicketOverview, setClientTicketOverview] = useState();
    const timeRangeOptions = [
        { value: 'last_30_days', label: 'Last 30 days' },
        { value: 'last_3_months', label: 'Last 3 Months' },
        { value: 'last_6_months', label: 'Last 6 Months' },
        { value: 'last_year', label: 'Last year' },
    ];
    const [totalBilledTimeRange, setTotalBilledTimeRange] = useState(timeRangeOptions[0]);
    const [loadingBilledTimeChart, setLoadingBilledTimeChart] = useState(true);

    const [clientSettingsModalOpen, { open: onClientSettingsModalOpen, close: onClientSettingsModalClose }] = useDisclosure(false);

    const totalBilledChartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    const totalBilledTimeChartRef = useRef(null);
    const timeChartInstanceRef = useRef(null);

    const hasContractDates = client && client.type === "Maintenance";

    const items = [
        { title: 'Clients', href: '/clients' },
        { title: client?.name, href: `/clients/${client?.id}` },
    ].map((item, index) => (
        <Link to={item.href} key={index} className="text-decoration-none tif-color-hotel">
            {item.title}
        </Link>
    ));

    const { id } = useParams();

    const navigate = useNavigate();

    const getFieldValue = (values = [], fieldName = "") => {
        const field = values.find(value => value.field_name === toIdentifier(fieldName));

        if (field) {
            return field.field_value;
        }

        return null;
    };

    const getFieldValueId = (values = [], fieldName = "") => {
        const field = values.find(value => value.field_name === toIdentifier(fieldName));

        if (field) {
            return field.field_value_id;
        }

        return null;
    };

    const handleValueChange = (groupId, fieldName, value) => {
        if (value === true) {
            value = "true";
        } else if (value === false) {
            value = "false";
        }

        setClientDetails(prevState => {
            const newState = [...prevState];
            const groupIndex = newState.findIndex(group => group.field_group_id === groupId);
            const fieldIndex = newState[groupIndex].fields_values.findIndex(field => field.field_name === toIdentifier(fieldName));
            return newState.map((group, index) => {
                if (index === groupIndex) {
                    return {
                        ...group,
                        fields_values: group.fields_values.map((field, index) => {
                            if (index === fieldIndex) {
                                return {
                                    ...field,
                                    field_value: value
                                };
                            }
                            return field;
                        })
                    };
                }
                return group;
            });
        });
    };

    const getMonthsRemaining = date => {
        const today = new Date();
        const targetDate = new Date(date);

        today.setHours(0, 0, 0, 0);
        targetDate.setHours(0, 0, 0, 0);

        const differenceInTime = Math.abs(today.getTime() - targetDate.getTime());

        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

        return (differenceInDays / 30.4).toFixed(1);
    };

    const total = clientTicketCounts ? clientTicketCounts[0].total + clientTicketCounts[1].total + clientTicketCounts[2].total + clientTicketCounts[3].total : 0;

    const clientTicketCountsStatsData = clientTicketCounts ? [
        { label: 'On Hold', icon: IconClockStop, count: clientTicketCounts[0].total, part: Math.round((clientTicketCounts[0].total / total) * 100), color: "gray" },
        { label: 'Open', icon: IconClockQuestion, count: clientTicketCounts[1].total, part: Math.round((clientTicketCounts[1].total / total) * 100), color: "#40c057" },
        { label: 'In Progress', icon: IconClockPlay, count: clientTicketCounts[2].total, part: Math.round((clientTicketCounts[2].total / total) * 100), color: "#228be6" },
        { label: 'Customer Review', icon: IconClockSearch, count: clientTicketCounts[3].total, part: Math.round((clientTicketCounts[3].total / total) * 100), color: "#fd7e14" },
    ] : [];

    const segments = clientTicketCountsStatsData.map((segment) => (
        <Progress.Section value={segment.part} color={segment.color} key={segment.color}>
            {segment.part > 10 && <Progress.Label>{segment.part}%</Progress.Label>}
        </Progress.Section>
    ));

    const descriptions = clientTicketCountsStatsData.map((stat) => (
        <Box key={stat.label} style={{ borderBottomColor: stat.color }} className="stat">
            <Text tt="uppercase" fz="sm" c="dimmed" fw={700}>
                {stat.label}
            </Text>

            <Group justify="space-between" align="flex-end" gap={0}>
                <Text fw={700}>{stat.count}</Text>
                <Text c={stat.color} fw={700} size="md" className="statCount">
                    {stat.part}%
                </Text>
            </Group>
        </Box>
    ));

    useEffect(() => {
        (async () => {
            const clientResponse = await getClient(id);
            const clientDetailsResponse = await getClientDetails(id);

            if (clientResponse.success && clientResponse.data.rows.length && clientDetailsResponse.success) {
                setClient({
                    ...clientResponse.data.rows[0],
                    total_billed_this_month: clientResponse.totalBilledThisMonth,
                    total_billed_last_month: clientResponse.totalBilledLastMonth,
                    total_billed_chart_values: clientResponse.totalBilledChartValues
                });


                if (clientResponse.data.rows[0].type === "Maintenance") {
                    setClientTotalBilled(clientResponse.totalBilled);
                }

                setClientTotalBudget(clientResponse.data.rows[0].total_budget);
                setClientMonthlyBudget(clientResponse.data.rows[0].monthly_budget);

                if (clientResponse.stats) {
                    const { open, inProgress, internalReview, customerReview, done, onHold, highPriority, whatsHot } = clientResponse.stats;
                    setClientTicketCounts(clientResponse.stats.ticketCount);
                    setClientTicketOverview({ open, inProgress, internalReview, customerReview, done, onHold, highPriority, whatsHot });
                }

                const reorderedClientDetails = clientDetailsResponse.data.rows.sort((a, b) => {
                    if (a.field_group_client_type === null && b.field_group_client_type !== null) {
                        return -1;
                    }
                    if (a.field_group_client_type !== null && b.field_group_client_type === null) {
                        return 1;
                    }
                    if (a.field_group_client_type === null && b.field_group_client_type === null) {
                        return a.id - b.id;
                    }
                    if (a.field_group_client_type !== null && b.field_group_client_type !== null) {
                        return a.id - b.id;
                    }
                    return 0;
                });

                setClientDetails(reformatClientDetails(reorderedClientDetails));
            } else {
                navigate('/clients');
            }

            const clientBilledTimeChartResponse = await getBilledTimeChartValues(id);
            if(clientBilledTimeChartResponse.success) {
                setClient(prevState => ({
                    ...prevState,
                    total_billed_time_chart_values: clientBilledTimeChartResponse.totalBilledTimeChartValues,
                }));
                setLoadingBilledTimeChart(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (client && client.type !== "Maintenance") {
            (async () => {
                const response = await getClientTotalBilled(client.key, totalBilledTimeRange.value);

                if (response.success) {
                    setClientTotalBilled(response.totalBilled);
                }
            })();
        }
    }, [client, totalBilledTimeRange]);

    useEffect(() => {
        if (client && totalBilledChartRef.current && hasContractDates) {
            const startDate = new Date(client.contract_start_date);
            const endDate = new Date(client.contract_end_date);

            const months = [];
            while (startDate <= endDate) {
                months.push(startDate.toLocaleString('default', { month: 'long' }));
                startDate.setDate(1);
                startDate.setMonth(startDate.getMonth() + 1);
            }

            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            const chart = new ApexCharts(totalBilledChartRef.current, {
                chart: {
                    type: "bar",
                    fontFamily: 'inherit',
                    height: 40.0,
                    sparkline: {
                        enabled: true
                    },
                    animations: {
                        enabled: false
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%',
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                },
                series: [{
                    name: "Billed Hours",
                    data: client.total_billed_chart_values.map(value => (value / 3600).toFixed(1))
                }],
                tooltip: {
                    theme: 'dark'
                },
                grid: {
                    strokeDashArray: 4,
                },
                xaxis: {
                    labels: {
                        padding: 0,
                    },
                    tooltip: {
                        enabled: false
                    },
                    axisBorder: {
                        show: false,
                    },
                    type: 'text',
                },
                yaxis: {
                    labels: {
                        padding: 4
                    },
                },
                labels: months,
                colors: ['#0d6efd'],
                legend: {
                    show: false,
                }
            });
            chart.render();

            if (totalBilledTimeChartRef.current && client.total_billed_time_chart_values) {
                timeChartInstanceRef.current && timeChartInstanceRef.current.destroy();
                const generateWeeklyDates = (startDate, endDate) => {
                    const formatDate = d => {
                        const options = { day: '2-digit', month: 'short', year: 'numeric' };
                        return d.toLocaleDateString('en-GB', options);
                    };
                
                    const dates = [];
                    let currentDate = new Date(startDate);
                    const today = new Date();
                    let todayAdded = false;
                
                    while (currentDate <= new Date(endDate)) {
                        dates.push(formatDate(currentDate));
                
                        const nextDate = new Date(currentDate);
                        nextDate.setDate(currentDate.getDate() + 7);
                
                        if (nextDate > today && !todayAdded) {
                            dates.push(formatDate(today));
                            todayAdded = true;
                        }
                
                        currentDate.setDate(currentDate.getDate() + 7);
                    }
                
                    return dates;
                };

                const getMonthsBetweenDates = (startDate, endDate) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                
                    const yearsDifference = end.getFullYear() - start.getFullYear();
                    const monthsDifference = end.getMonth() - start.getMonth();
                    const daysDifference = end.getDate() - start.getDate();
                
                    let totalMonths = yearsDifference * 12 + monthsDifference;
                
                    if (daysDifference >= 0) {
                        totalMonths += 1;
                    }
                
                    return totalMonths;
                };

                const monthNum = getMonthsBetweenDates(client.contract_start_date, client.contract_end_date);

                const monthlyBudgetArray = Array.from({ length: monthNum }, (_, i) => {
                    return (clientTotalBudget / monthNum) * (i + 1);
                });

                const weeklyDates = generateWeeklyDates(client.contract_start_date, client.contract_end_date);

                const calculatePortion = (startDate, endDate, number) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const today = new Date();
                
                    if (today < start) {
                        return 0;
                    } else if (today > end) {
                        return number;
                    }
                
                    const totalTimeSpan = end - start;
                
                    const elapsedTimeSpan = today - start;
                
                    const percentageElapsed = elapsedTimeSpan / totalTimeSpan;
                
                    const portion = number * percentageElapsed;
                
                    return portion;
                };

                const getMonthName = (startDate, endDate, monthOffset) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                
                    const offsetDate = new Date(start);
                    offsetDate.setUTCMonth(start.getUTCMonth() + monthOffset);
                
                    if (offsetDate > end) {
                        return null;
                    }
                
                    return offsetDate.toLocaleDateString('en-US', { month: 'long', timeZone: 'UTC' });
                };

                const timeChart = new ApexCharts(totalBilledTimeChartRef.current, {
                    series: [{
                        name: "Total Hours Billed",
                        data: client.total_billed_time_chart_values.map(value => (value / 3600).toFixed(1))
                    }],
                    chart: {
                        type: 'area',
                        height: 500,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    labels: weeklyDates,
                    xaxis: {
                        type: 'datetime',
                    },
                    yaxis: {
                        opposite: true,
                        max: clientTotalBudget,
                        min: 0,
                        tickAmount: getMonthsBetweenDates(client.contract_start_date, client.contract_end_date),
                    },
                    legend: {
                        horizontalAlign: 'left'
                    },
                    toolbar: {
                        show: false
                    },
                    tooltip: {
                        theme: 'dark'
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: calculatePortion(client.contract_start_date, client.contract_end_date, clientTotalBudget),
                                borderColor: '#c92a2a',
                                strokeDashArray: 3,
                                label: {
                                    borderColor: '#c92a2a',
                                    style: {
                                        color: '#fff',
                                        background: '#c92a2a'
                                    },
                                    text: 'Maximum Budget',
                                }
                            },
                            ...monthlyBudgetArray.map((value, index) => {
                                return {
                                    y: value,
                                    label: {
                                        text: getMonthName(client.contract_start_date, client.contract_end_date, index),
                                        offsetX: index * 35,
                                        position: 'left',
                                        textAnchor: 'start',
                                    },
                                };
                            }), 
                        ]
                    },
                    grid: {
                        show: true
                    }
                });
                timeChart.render();
                timeChartInstanceRef.current = timeChart;
            }

            chartInstanceRef.current = chart;
        };

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            if (timeChartInstanceRef.current) {
                timeChartInstanceRef.current.destroy();
            }
        };
    }, [client, totalBilledChartRef.current]);

    return (
        <ContentWrapper className="scrollable">
            <div id="single-client" className="wrapper">
                {
                    client ?
                        <>
                            <Group justify="space-between">
                                <Breadcrumbs className="tif-font-xs text-uppercase" mb={30}>{items}</Breadcrumbs>
                                <ActionIcon variant="subtle" size="lg" onClick={() => onClientSettingsModalOpen(true)}>
                                    <IconSettings style={{ width: 24, height: 24 }} stroke={1.5} />
                                </ActionIcon>
                            </Group>
                            <Group>
                                <Avatar size={60} src={client.avatar_url} radius={40} />
                                <Box>
                                    <EditableField element="h1" name="clients_name" id={client.id} className="tif-mb-0" size="xl">{client.name}</EditableField>
                                    <EditableField element={Text} name="clients_type" id={client.id} className="tif-mb-0" choices={projectTypes} onSave={value => setClient(prevState => ({ ...prevState, type: value }))}>{client.type}</EditableField>
                                </Box>
                            </Group>
                            <Group mt="30" align="start" wrap="nowrap">
                                <Paper withBorder p="md" radius="md" className="client-sidebar">
                                    <Group justify="space-between" mb="15">
                                        <Text c="dimmed" fz="xs" className="text-uppercase">
                                            Client Details
                                        </Text>
                                        <IconListSearch size="1.4rem" className="icon" stroke={1.5} />
                                    </Group>
                                    <Accordion multiple variant="separated" defaultValue={["general", ...clientDetails.map(detail => detail.field_group_name)]}>
                                        <Accordion.Item value="general">
                                            <Accordion.Control>
                                                General
                                            </Accordion.Control>
                                            <Accordion.Panel>
                                                {
                                                    client ?
                                                        <>
                                                            {
                                                                client.project_lead &&
                                                                <Group gap="sm" mb="15">
                                                                    <Avatar size={40} src={client.project_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.project_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Project Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                            {
                                                                client.design_lead &&
                                                                <Group gap="sm" mb="15">
                                                                    <Avatar size={40} src={client.design_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.design_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Design Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                            {
                                                                client.tech_lead &&
                                                                <Group gap="sm">
                                                                    <Avatar size={40} src={client.tech_lead_avatar_url} radius={40} />
                                                                    <div>
                                                                        <Text fz="sm" fw={500}>
                                                                            {client.tech_lead}
                                                                        </Text>
                                                                        <Text c="dimmed" fz="xs">
                                                                            Tech Lead
                                                                        </Text>
                                                                    </div>
                                                                </Group>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Website URL</Text>
                                                <EditableField element="a" name="clients_website-url" id={id} type="URL" value={client.website_url} className="tif-mb-0" size="xl" required={false} onSave={website_url => setClient(prevState => ({ ...prevState, website_url }))} href={client.website_url} target="_blank">
                                                    {
                                                        client.website_url ?
                                                            <Anchor href={client.website_url} target="_blank">{client.website_url}</Anchor>
                                                            :
                                                            <Text c="dimmed" size="md">(empty)</Text>
                                                    }
                                                </EditableField>

                                                <ClientContacts id={id} client={client} contacts={client.contacts} onChange={setClient} />

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">TIF Hosted</Text>
                                                <EditableField element={Text} name="clients_tif-hosted" id={client.id} className="tif-mb-0" onSave={value => setClient(prevState => ({ ...prevState, tif_hosted: value && value !== "(empty)" ? true : false }))} required={false} value={client.tif_hosted} type="Yes/No">{client.tif_hosted ? "Yes" : (client.tif_hosted === null ? "(empty)" : "No")}</EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Host</Text>
                                                <EditableField element={Text} name="clients_host" id={client.id} className="tif-mb-0" choices={hosts} onSave={value => setClient(prevState => ({ ...prevState, host: value }))} allowDeselect required={false}>{client.host || "(empty)"}</EditableField>

                                                <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">{ client.type === "Targets" ? "Annual Target" : "Total Budget" }</Text>
                                                <EditableField element={Text} type="Number" className="tif-mb-0" name="clients_total-budget" id={client.id} required={false} onSave={value => setClientTotalBudget(value)}>{clientTotalBudget || 0}</EditableField>

                                                {
                                                    client.type !== "Project" ?
                                                        <>
                                                            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">{ client.type === "Targets" ? "Monthly Target" : "Monthly Budget" }</Text>
                                                            <EditableField element={Text} type="Number" className="tif-mb-0" name="clients_monthly-budget" id={client.id} required={false} onSave={value => setClientMonthlyBudget(value)}>{clientMonthlyBudget || 0}</EditableField>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    hasContractDates ?
                                                        <>
                                                            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contract Start Date</Text>
                                                            <EditableField element={Text} name="clients_contract-start-date" type="Date Picker" id={id} value={client.contract_start_date ? new Date(client.contract_start_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : null} className="tif-mb-0" size="md" required={false} onSave={contract_start_date => setClient(prevState => ({ ...prevState, contract_start_date }))} clearable maxDate={client.contract_end_date ? new Date(client.contract_end_date) : null}>
                                                                <Text c="dimmed" size="md">{client.contract_start_date ? new Date(client.contract_start_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "(empty)"}</Text>
                                                            </EditableField>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    hasContractDates ?
                                                        <>
                                                            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt="15px">Contract End Date</Text>
                                                            <EditableField element={Text} name="clients_contract-end-date" type="Date Picker" id={id} value={client.contract_end_date ? new Date(client.contract_end_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : null} className="tif-mb-0" size="md" required={false} onSave={contract_end_date => setClient(prevState => ({ ...prevState, contract_end_date }))} clearable minDate={client.contract_start_date ? new Date(client.contract_start_date) : null}>
                                                                <Text c="dimmed" size="md">{client.contract_end_date ? new Date(client.contract_end_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : "(empty)"}</Text>
                                                            </EditableField>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                        {
                                            clientDetails.map((detail, index) => (
                                                <Accordion.Item value={detail.field_group_name} key={index}>
                                                    <Accordion.Control>
                                                        {
                                                            detail.field_group_global ?
                                                                <>{detail.field_group_name}</>
                                                                :
                                                                <EditableField id={detail.field_group_id} element="span" name="field-groups_name" className="tif-mb-0" size="xl">{detail.field_group_name}</EditableField>
                                                        }
                                                    </Accordion.Control>
                                                    <Accordion.Panel>
                                                        {
                                                            detail.field_group_fields.map((field, index) => (
                                                                <Element id={getFieldValueId(detail.fields_values, field.name)} name={field.name} type={field.type} value={getFieldValue(detail.fields_values, field.name)} position={index} groupId={detail.field_group_id} onChange={handleValueChange} key={index} />
                                                            ))
                                                        }
                                                    </Accordion.Panel>
                                                </Accordion.Item>
                                            ))
                                        }
                                    </Accordion>
                                </Paper>
                                <div className="flex-grow-1">
                                    <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-20">
                                        <Card title={ client.type === "Targets" ? "Annual Target" : "Total Budget" } Icon={IconCoin}>
                                            <Text className="value tif-font-xl tif-mt-25 tif-mb-10">{clientTotalBudget || 0}</Text>
                                        </Card>
                                        <Card title="Total Billed" Icon={IconReceipt2}>
                                            {
                                                client.type !== "Maintenance" ?
                                                    <Select
                                                        placeholder="Pick value"
                                                        data={timeRangeOptions}
                                                        allowDeselect={false}
                                                        size="xs"
                                                        variant="filled"
                                                        value={totalBilledTimeRange ? totalBilledTimeRange.value : null}
                                                        onChange={(_value, option) => setTotalBilledTimeRange(option)}
                                                    />
                                                    :
                                                    <></>
                                            }
                                            <p className={`value ${hasContractDates ? 'tif-font-lg tif-mb-10' : 'tif-font-xl tif-mt-25 tif-mb-10'}`}>{(clientTotalBilled / 3600).toFixed(1)}</p>
                                            <div ref={totalBilledChartRef}></div>
                                        </Card>
                                        <Card title="Total Remaining" Icon={IconCalendarDollar}>
                                            <Text color={(clientTotalBudget - (clientTotalBilled / 3600)) > 0 ? "inherit" : "red"} className="value tif-font-xl tif-mt-25 tif-mb-10">{(clientTotalBudget - (clientTotalBilled / 3600)).toFixed(1)}</Text>
                                        </Card>
                                        {
                                            hasContractDates ?
                                                <Card title="Months Remaining" progressColor="none" progress={client.contract_end_date ? ((getMonthsRemaining(client.contract_end_date) / 12) * 100) : "0"}>
                                                    <Text fw={700} size="xl">
                                                        {client.contract_end_date ? getMonthsRemaining(client.contract_end_date) : 0}
                                                    </Text>
                                                </Card>
                                                :
                                                <></>
                                        }
                                        <Card title="Monthly Budget" Icon={IconCalendarDollar}>
                                            <Text className="value tif-font-xl tif-mt-25 tif-mb-10">{clientMonthlyBudget || 0}</Text>
                                        </Card>
                                        <Card title="Billed Last Month" Icon={IconCalendarUp}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">{(client.total_billed_last_month / 3600).toFixed(1)}</p>
                                        </Card>
                                        <Card title="Billed This Month" Icon={IconCalendarMonth}>
                                            <p className="value tif-font-xl tif-mt-25 tif-mb-10">{(client.total_billed_this_month / 3600).toFixed(1)}</p>
                                        </Card>
                                        {
                                            hasContractDates ?
                                                <Card title="Avg. Hours Per Month Remaining" Icon={IconCalendarClock}>
                                                    <p className="value tif-font-xl tif-mt-25 tif-mb-10">{((clientTotalBudget - (clientTotalBilled / 3600)).toFixed(1) / getMonthsRemaining(client.contract_end_date)).toFixed(1)}</p>
                                                </Card>
                                                :
                                                <></>
                                        }
                                    </SimpleGrid>

                                    {
                                        client.type == "Maintenance" ?
                                            client.total_billed_time_chart_values ?
                                                <Box mb="20px">
                                                    <Card title="Billed Time Chart" Icon={IconReceipt2}>
                                                        <div ref={totalBilledTimeChartRef}></div>
                                                    </Card>
                                                </Box>
                                            :
                                                <Skeleton mb="20px" height={500} />
                                        :
                                            <></>
                                    }

                                    <ProgressBarCard segments={segments} descriptions={descriptions} total={total} cols={({ base: 1, md: 4 })} mb="20px" />

                                    <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-40">
                                        <Card title="Open" Icon={IconClockQuestion} count={clientTicketOverview.open[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.open[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20IN%20%28Open%2C%20"To%20Do"%29%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="In Progress" Icon={IconClockPlay} count={clientTicketOverview.inProgress[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.inProgress[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20statuscategory%20%3D%20"In%20Progress"%20AND%20status%20%21%3D%20"Client%20Review"%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="Internal Review" Icon={IconClockSearch} count={clientTicketOverview.internalReview[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.internalReview[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20status%20%3D%20"Internal%20Review"%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="Customer Review" Icon={IconClockSearch} count={clientTicketOverview.customerReview[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.customerReview[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20status%20%3D%20"Client%20Review"%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="Done" Icon={IconCircleCheck} count={clientTicketOverview.done[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.done[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20status%20%3D%20"Done"%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="On Hold" Icon={IconClockStop} count={clientTicketOverview.onHold[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.onHold[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"On%20Hold"%20ORDER%20BY%20updated%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="High Priority" Icon={IconAlertTriangle} count={clientTicketOverview.highPriority[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.highPriority[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20priority%20IN%20%28High%2C%20Highest%29%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                        <Card title="What's Hot" Icon={IconFlame} count={clientTicketOverview.whatsHot[0].total}>
                                            {
                                                <>
                                                    {listTickets(clientTicketOverview ? clientTicketOverview.whatsHot[0].issues : [])}
                                                    <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=project%20%3D%20"${client.name}"%20AND%20workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                                </>
                                            }
                                        </Card>
                                    </SimpleGrid>
                                </div>
                            </Group>
                        </>
                        :
                        <>
                            <Group justify="space-between">
                                <Skeleton height={15} width={150} radius="md" mb="30px" />
                                <Skeleton height={35} width={35} radius="md" />
                            </Group>
                            <Skeleton height={40} width={175} radius="md" mb="5px" />
                            <Skeleton height={25} width={95} radius="md" />
                            <Group align="start" wrap="nowrap" mt="30px" mb="20px">
                                <Skeleton height={485} width="400px" radius="md" />
                                <div className="flex-grow-1">
                                    <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-20">
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                        <Skeleton height={145} radius="md" />
                                    </SimpleGrid>
                                    <Skeleton height={165} radius="md" mb="20px" />
                                    <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-20">
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                        <Skeleton height={435} radius="md" />
                                    </SimpleGrid>
                                </div>
                            </Group>
                        </>
                }
            </div>
            <ClientSettingsModal
                opened={clientSettingsModalOpen}
                close={onClientSettingsModalClose}
                client={client}
                id={id}
                onClientChange={setClient}
                onConfirmationModalOpen={onClientSettingsModalOpen}
            />
        </ContentWrapper>
    );
};

export default SingleClient;
