import { useUserState } from "../../views/components/UserStateContext";
import { Text, Button, Modal, Stepper, Group, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { saveJiraApiToken, reAuthenticateUser } from "../../data/dataApi";

const InitialSetupModal = () => {
    const { hasTempoTokens, hasJiraApiToken, setHasJiraApiToken } = useUserState();

    let initialStep = 0;

    if(hasTempoTokens) {
        initialStep = 1;
    } else if(hasJiraApiToken && hasTempoTokens) {
        initialStep = 2;
    }

    const [initialSetupModalOpen, {close}] = useDisclosure(true);
    const [active, setActive] = useState(initialStep);
    const [jiraApiToken, setJiraApiToken] = useState('');
    const [focused, setFocused] = useState(false);
    const [jiraApiTokenError, setJiraApiTokenError] = useState(false);
    const [savingJiraApiToken, setSavingJiraApiToken] = useState(false);

    const floating = jiraApiToken.trim().length !== 0 || focused || undefined;

    const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));

    const getTempoAuthorizationCode = () => {
        window.location.href = `https://thinkitfirst.atlassian.net/plugins/servlet/ac/io.tempo.jira/oauth-authorize/?client_id=${process.env.REACT_APP_TEMPO_CLIENT_ID}&redirect_uri=${ encodeURIComponent(`${ process.env.NODE_ENV === "development" ? process.env.REACT_APP_NGROK_URL : "https://dashboard.thinkitfirst.com" }/tempo-auth`) }`;
    };

    const handleSaveJiraApiToken = async () => {
        setJiraApiTokenError(false);
        if(!hasJiraApiToken) {
            setSavingJiraApiToken(true);
            const jiraApiTokenSaveResponse = await saveJiraApiToken(jiraApiToken);
            const reAuthResponse = await reAuthenticateUser();

            if(jiraApiTokenSaveResponse.success && reAuthResponse.success) {
                setJiraApiToken("");
                nextStep();
            } else {
                setJiraApiTokenError(true);
            }

            setSavingJiraApiToken(false);
        } else {
            nextStep();
        }
    };

    return (
        <Modal.Root
            opened={initialSetupModalOpen}
            onClose={close}
            title="Authorizations"
            size="xl"
            closeOnClickOutside={false}
        >
            <Modal.Overlay blur="3" />
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>Before you proceed...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stepper active={active} onStepClick={setActive}>
                        <Stepper.Step label="Step 1" description="Tempo Access" allowStepSelect={false}>
                            <Text fw={500} fz="lg" mb={5}>
                                Tempo Access Authorization Required
                            </Text>
                            <Text fz="sm" c="dimmed" className="tif-mb-30">
                                First step is to authorize access to your Tempo account.
                            </Text>

                            <Button onClick={getTempoAuthorizationCode} color="green" className="banner-control" disabled={hasTempoTokens}>{hasTempoTokens ? "Authorized" : "Authorize"}</Button>

                            <Group justify="center" mt="xl">
                                <Button onClick={nextStep} disabled={!hasTempoTokens}>Next step</Button>
                            </Group>
                        </Stepper.Step>
                        <Stepper.Step label="Step 2" description="Jira Token" allowStepSelect={false}>
                            <Text fw={500} fz="lg" mb={5}>
                                Jira API Key Authorization Required
                            </Text>
                            <Text fz="sm" c="dimmed" className="tif-mb-30">
                                Second step is to authorize access to your Jira account. Follow the instructions below to get your Jira API token.
                            </Text>
                            <ol>
                                <li>Open <Link to="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank">this</Link> link.</li>
                                <li>Click on "Create API Token".</li>
                                <li>In the label field, enter a name for your token i.e. "tif-dashboard".</li>
                                <li>Click the "Create" button.</li>
                                <li>Click on "Copy" button.</li>
                                <li>Paste the token in the field below.</li>
                            </ol>

                            <TextInput
                                label="Jira API Token"
                                required
                                classNames={{ root: "floating-input", label: "label", required: "required", input: "input" }}
                                className="tif-mt-30"
                                value={jiraApiToken}
                                onChange={(event) => setJiraApiToken(event.currentTarget.value)}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                mt="md"
                                autoComplete="nope"
                                data-floating={floating}
                                labelProps={{ 'data-floating': floating }}
                                error={ jiraApiTokenError ? "Something went wrong" : false }
                                disabled={hasJiraApiToken}
                            />
                            <Group justify="center" mt="xl">
                                <Button onClick={handleSaveJiraApiToken} loading={savingJiraApiToken} disabled={!jiraApiToken && !hasJiraApiToken}>Next step</Button>
                            </Group>
                        </Stepper.Step>
                        <Stepper.Completed>
                            All done!
                            <Group justify="center" mt="xl">
                                <Button variant="filled" onClick={() => setHasJiraApiToken(true)}>Continue</Button>
                            </Group>
                        </Stepper.Completed>
                    </Stepper>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};

export default InitialSetupModal;