import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Group, Select, Text, Textarea, PasswordInput, CopyButton, NumberInput, Switch, TextInput } from "@mantine/core";
import { IconCheck, IconCopy, IconPencil, IconX } from "@tabler/icons-react";
import { useUserState } from "./UserStateContext";
import { updateField, createField } from '../../data/dataApi';
import { showSuccessNotification, showUnexpectedErrorNotification } from "../helpers";
import { DateInput } from "@mantine/dates";

const EditableField = ({ element, children, name, id, choices, focus, newGroup, position, onSave, required, groupId, fieldName, hiddenValue, value, type, allowDeselect, clearable, minDate, maxDate, clientType, onGroupUpdate, list, fieldKey, index, groupName, ...props }, ref) => {
    const { globalEditingMode, setGlobalEditingMode } = useUserState();

    let initialValue = hiddenValue ? hiddenValue : children;
    initialValue = value || initialValue;

    if(type === "Number") {
        initialValue = Number(initialValue) || "(empty)";
    }

    const [showEditBtn, setShowEditBtn] = useState(false);
    const [editingMode, setEditingMode] = useState(false);
    const [fieldValue, setFieldValue] = useState(initialValue);
    const [styles, setStyles] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const editableFieldWrapperRef = useRef(null);
    const elementRef = useRef();
    const editFieldRef = useRef();
    const cursorPositionRef = useRef(null);

    const disableEditingMode = () => {
        cursorPositionRef.current = null;
        setEditingMode(false);
        setShowEditBtn(false);
    };

    const validateField = () => {
        setError(null);

        let newValue = fieldValue;
        if (type === "Date Picker" && fieldValue) {
            newValue = new Date(fieldValue);
        } else if (type !== "Number" && type !== "Yes/No" && fieldValue === undefined) {
            newValue = fieldValue.trim();
        }

        if (required === undefined || required) {
            if (!newValue) {
                setError("This field is required.");
                return false;
            }
        }

        return true;
    };

    const handleSaveEdit = async e => {
        if (validateField()) {
            setLoading(true);
            e.stopPropagation();
            const match = name.match(/\b([a-zA-Z0-9-]+)_([a-zA-Z0-9-]+)\b/);
            if (match) {
                const [fullMatch, table, column] = match;

                let response,
                    newValue = fieldValue;

                if (type === "Date Picker" && fieldValue) {
                    newValue = new Date(fieldValue);
                } else if (type !== "Number" && type !== "Yes/No" && fieldValue !== null) {
                    newValue = fieldValue.trim();
                }

                if(list && fieldKey) {
                    list[index][fieldKey] = fieldValue;
                    newValue = list;
                }

                if (newGroup) {
                    response = await createField(table.replace(/-/g, "_"), column.replace(/-/g, "_",), newValue, clientType);
                } else {
                    response = await updateField(id, table.replace(/-/g, "_"), column.replace(/-/g, "_"), newValue);
                }

                if (response.success) {
                    if(!list || !fieldKey) {
                        if (newGroup && onSave) {
                            onSave({
                                id: response.data.rows[0].id,
                                position,
                                name: newValue,
                            });
                        } else if (fieldName && groupId && onSave) {
                            onSave(groupId, fieldName, newValue);
                        } else if(groupName) {
                            onSave({
                                id: groupId,
                                position,
                                name: newValue,
                            });
                        } else if (onSave) {
                            onSave(newValue);
                        }
                    } else {
                        onSave(fieldValue);
                    }

                    disableEditingMode();
                    showSuccessNotification("Field updated successfully.");

                    if (!newValue && type !== "Yes/No") {
                        setFieldValue(type === "Number" ? 0 : "(empty)");
                    }
                } else {
                    showUnexpectedErrorNotification();
                }

                setLoading(false);
            }
        }
    };

    const handleCancelEditing = e => {
        if(newGroup) {
            onGroupUpdate(prevState => prevState.slice(0, -1));
            setGlobalEditingMode(false);
        } else {
            e.stopPropagation();
            setFieldValue(initialValue);
        }

        disableEditingMode();
    };

    const handleBlur = useCallback((event) => {
        event.preventDefault();
        if (editFieldRef.current) {
            editFieldRef.current.focus();
        }
    }, []);

    const handleFocus = () => {
        if (editFieldRef.current.innerText === '\u00A0') {
            editFieldRef.current.innerText = '';
        }
    };

    const handleFocusAlert = (event) => {
        if (editableFieldWrapperRef.current && (editableFieldWrapperRef.current.contains(event.target) || (event.target.closest('[data-portal]')) && !event.target.closest('.mantine-Modal-inner'))) {
            return;
        }

        if (editingMode) {
            event.stopImmediatePropagation();
            event.preventDefault();
            alert("Please save or cancel your changes before continuing.");
        }
    };

    useEffect(() => {
        if (elementRef.current) {
            const styles = window.getComputedStyle(elementRef.current);
            setStyles({
                fontSize: styles.fontSize,
                height: styles.height
            });
        }

        if (focus) {
            setEditingMode(true);
        }
    }, []);

    useEffect(() => {
        setGlobalEditingMode(editingMode);
    
        if (editingMode && editFieldRef.current) {
            const field = editFieldRef.current;
            field.focus();
    
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(field);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
    
            field.addEventListener('blur', handleBlur);
        }
    
        if (editingMode) {    
            const timeoutId = setTimeout(() => {
                document.addEventListener('mousedown', handleFocusAlert);
            }, 0);
    
            return () => {
                clearTimeout(timeoutId);
                document.removeEventListener('mousedown', handleFocusAlert);
            };
        } else {
            document.removeEventListener('mousedown', handleFocusAlert);
        }
    
        return () => {
            if (editFieldRef.current) {
                editFieldRef.current.removeEventListener('blur', handleBlur);
            }
    
            document.removeEventListener('mousedown', handleFocusAlert);
        };
    }, [editingMode]);

    const handleInput = (e) => {
        const selection = window.getSelection();
        cursorPositionRef.current = selection.getRangeAt(0).endOffset;
        setFieldValue(e.currentTarget.textContent);
    };

    useEffect(() => {
        if (editFieldRef.current && cursorPositionRef.current !== null) {
            let value = fieldValue;
            if (value.length > 1 && value.startsWith('\u00A0')) {
                value = value.slice(1);
                cursorPositionRef.current = Math.max(cursorPositionRef.current - 1, 0);
                editFieldRef.current.innerText = value;
            }

            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(editFieldRef.current.childNodes[0], cursorPositionRef.current);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }, [fieldValue]);

    useEffect(() => {
        setFieldValue((initialValue?.props?.children === "(empty)" && editingMode) ? "" : initialValue);
    }, [children, hiddenValue]);

    return (
        <Group className="editable-field-wrapper position-relative" ref={editableFieldWrapperRef} gap={10} align="center" wrap="nowrap" onMouseEnter={() => setShowEditBtn(true)} onMouseLeave={() => !editingMode ? setShowEditBtn(false) : null}>
            {
                editingMode ?
                    <>
                        {
                            choices ?
                                <Select
                                    ref={editFieldRef}
                                    size="xs"
                                    value={fieldValue}
                                    data={choices}
                                    onChange={value => setFieldValue(value)}
                                    allowDeselect={allowDeselect}
                                />
                            :
                                type === "Address" ?
                                    <Textarea
                                        value={fieldValue}
                                        onChange={e => setFieldValue(e.target.value)}
                                        resize="vertical"
                                    />
                            :
                                type === "Password" ?
                                    <PasswordInput
                                        value={fieldValue}
                                        size="xs"
                                        onChange={e => setFieldValue(e.target.value)}
                                        className="editable-field"
                                        style={{
                                            "--input-font-size": styles.fontSize,
                                            "--input-height": styles.height,
                                        }}
                                    />
                            :
                                type === "Date Picker" ?
                                    <DateInput
                                        value={fieldValue && fieldValue.props?.children !== "(empty)" ? new Date(fieldValue) : null}
                                        size="xs"
                                        onChange={setFieldValue}
                                        className="editable-field"
                                        style={{
                                            "--input-font-size": styles.fontSize,
                                            "--input-height": styles.height,
                                        }}
                                        clearable={clearable}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                            :
                                type === "Number" ?
                                    <NumberInput
                                        value={fieldValue}
                                        size="xs"
                                        onChange={setFieldValue}
                                        className="editable-field"
                                        style={{
                                            "--input-font-size": styles.fontSize,
                                            "--input-height": styles.height,
                                        }}
                                    />
                            :
                                type === "Yes/No" ?
                                    <Switch
                                        size="md"
                                        checked={fieldValue === true || fieldValue === "true"}
                                        onChange={e => setFieldValue(e.currentTarget.checked)}
                                        thumbIcon={
                                            (fieldValue === "true" || fieldValue === true) ? (
                                                <IconCheck
                                                    style={{ width: "12px", height: "12px" }}
                                                    color="teal"
                                                    stroke={3}
                                                />
                                            ) : (
                                                <IconX
                                                    style={{ width: "12px", height: "12px" }}
                                                    color="red"
                                                    stroke={3}
                                                />
                                            )
                                        }
                                        className="editable-field"
                                        style={{
                                            "--input-font-size": styles.fontSize,
                                            "--input-height": styles.height,
                                        }}
                                    />
                            :
                                type === "URL" || type === "Email" ?
                                    <TextInput
                                        size="xs"
                                        value={fieldValue}
                                        onChange={e => setFieldValue(e.target.value)}
                                        className="editable-field"
                                        style={{
                                            "--input-font-size": styles.fontSize,
                                            "--input-height": styles.height,
                                        }}
                                    />
                            :
                                    React.createElement(
                                        element,
                                        {
                                            ...props,
                                            className: "editable-field",
                                            contentEditable: "true",
                                            ref: editFieldRef,
                                            onInput: handleInput,
                                            onFocus: handleFocus,
                                            onKeyDown: e => e.key === "Enter" && e.preventDefault(),
                                            style: {
                                                "--input-font-size": styles.fontSize,
                                                "--input-height": styles.height,
                                                whiteSpace: element === "address" ? "pre-wrap" : "initial"
                                            }
                                        },
                                        fieldValue || '\u00A0'
                                    )
                        }
                        <Button.Group className="edit-btns position-absolute">
                            <Button color="gray" size="24" px={5} onClick={handleSaveEdit} loading={loading}><IconCheck size={14} /></Button>
                            <Button color="gray" size="24" px={5} onClick={handleCancelEditing}><IconX size={14} /></Button>
                        </Button.Group>
                    </>
                    :
                    <>
                        {
                            React.createElement(
                                element,
                                {
                                    ...props,
                                    ref: elementRef,
                                    style: {
                                        whiteSpace: element === "address" ? "pre-wrap" : "initial"
                                    }
                                },
                                hiddenValue ?
                                    "••••••••"
                                :
                                    fieldValue !== "(empty)" ? 
                                        (type === "Date Picker" && value) ?
                                            new Date(fieldValue).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
                                        :
                                        type === "Yes/No" ?
                                            fieldValue === true || fieldValue === "true" ? "Yes" : "No"
                                        :
                                            fieldValue
                                    :
                                        <Text c="dimmed" size="md">(empty)</Text>
                            )
                        }
                        {
                            showEditBtn &&
                            <Button.Group className="edit-btns position-absolute">
                                <Button color="gray" className="editable-field-btn" size="24" px={5} onClick={() => setEditingMode(true)} disabled={globalEditingMode}><IconPencil size={14} /></Button>
                                {
                                    fieldValue.props?.children !== "(empty)" &&
                                        <CopyButton value={fieldValue}>
                                            {({ copied, copy }) => (
                                                <Button size="24" px={5} color={copied ? 'teal' : 'gray'} onClick={copy}>
                                                    {copied ? <IconCheck size={14} /> : <IconCopy size={14} />}
                                                </Button>
                                            )}
                                        </CopyButton>
                                }
                            </Button.Group>
                        }
                    </>
            }
            {error && <Text c="red" size="sm">{error}</Text>}
        </Group>
    );
};

export default EditableField;