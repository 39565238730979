import { useUserState } from '../views/components/UserStateContext';
import AdminHome from './AdminHome';
import NonAdminHome from './NonAdminHome';
import InitialSetupModal from '../views/components/InitialSetupModal';

const Home = () => {
    const { userRole, hasTempoTokens, hasJiraApiToken } = useUserState();

    return (
        <>

            {
                (hasTempoTokens && hasJiraApiToken) ?
                    <>
                        {
                            userRole === "ops" ?
                                <AdminHome />
                            :
                                <NonAdminHome />
                        }
                    </>
                :
                (hasTempoTokens !== null && hasJiraApiToken !== null) ?
                    <InitialSetupModal />
                :
                    <></>
            }
        </>
    );
};

export default Home;