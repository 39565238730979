import ContentWrapper from '../views/components/ContentWrapper';
import { useEffect } from 'react';
import { getUser } from '../data/dataApi';
import { handleUserStats } from '../views/helpers';
import IndividualStatContent from '../views/components/IndividualStatContent';
import { Group, Text, Avatar, Skeleton, SimpleGrid } from '@mantine/core';
import { useUserState } from '../views/components/UserStateContext';
import UpdateStatus from '../views/components/UpdateStatus';

const NonAdminHome = () => {
    const { userStats, setUserStats, refresh, setRefresh } = useUserState([]);

    const user = userStats[0] || {};

    useEffect(() => {
        (async () => {
            if(refresh || !userStats.length) {
                sessionStorage.setItem('elapsedTime', '0');
                setRefresh(false);

                const response = await getUser();
                handleUserStats(response.data.rows[0], setUserStats);
            }
        })();
    }, [refresh]);

    return (
        <ContentWrapper className="scrollable">
            <div className="wrapper">
                {
                    userStats.length ?
                        <>
                            <Group wrap="nowrap" mb="30" justify="space-between">
                                <Group>
                                    <Avatar src={user.avatar_url} radius="xl" size="50" />
                                    <Text fz="lg">{user.name}</Text>
                                </Group>
                                <UpdateStatus />
                            </Group>
                            <IndividualStatContent user={user} />
                        </>
                    :
                        <>
                            <Group mb={30}>
                                <Skeleton height={50} width={50} circle />
                                <Skeleton height={30} width={100} radius="md" />
                                <Skeleton height={25} ml="auto" width={150} radius="md" />
                            </Group>
                            <Skeleton height={30} width={100} radius="md" className="tif-mb-10" />
                            <Skeleton height={166} radius="md" mb={30} />
                            <Skeleton height={30} width={100} radius="md" className="tif-mb-10" />
                            <Skeleton height={166} radius="md" mb={30} />
                            <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-40">
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                                <Skeleton height={400} radius="md" />
                            </SimpleGrid>
                        </>
                }
            </div>
        </ContentWrapper>
    );
};

export default NonAdminHome;