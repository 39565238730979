import { useState } from "react";
import { Table, Text, Popover, Group, Button, Anchor, ActionIcon } from "@mantine/core";
import { IconX, IconCheck } from "@tabler/icons-react";
import EditableField from "./EditableField";

const ContactItem = ({ clientId, contactName, contactEmail, onChange, position, contacts, onDelete }) => {
    const [deleteContactPopoverOpen, setDeleteContactPopoverOpen] = useState(false);

    return (
        <Table.Tr>
            <Table.Td>
                <EditableField element={Text} name="clients_contacts" id={clientId} value={contactName} className="tif-mb-0" size="md" required={false} onSave={contact_name => onChange(position, "contact_name", contact_name)} list={contacts} fz="sm"  fieldKey="contact_name" index={position}>
                    <Text c="dimmed" size="md" fz="sm">{contactName || "(empty)"}</Text>
                </EditableField>
            </Table.Td>
            <Table.Td>
                <EditableField element="a" name="clients_contacts" type="Email" id={clientId} value={contactEmail} className="tif-mb-0" size="md" required={false} onSave={contact_email => onChange(position, "contact_email", contact_email)} href={contactEmail ? `mailto:${contactEmail}` : null} list={contacts} fieldKey="contact_email" index={position}>
                    {
                        contactEmail ?
                            <Anchor href={contactEmail ? `mailto:${contactEmail}` : null} target="_blank" fz="sm">{contactEmail}</Anchor>
                        :
                            <Text c="dimmed" size="md" fz="sm">(empty)</Text>
                    }
                </EditableField>
            </Table.Td>
            <Table.Td width={28}>
                <Popover withArrow width={315} opened={deleteContactPopoverOpen} onChange={setDeleteContactPopoverOpen}>
                    <Popover.Target onClick={() => setDeleteContactPopoverOpen(true)}>
                        <ActionIcon variant="subtle" color="gray">
                            <IconX size={16} stroke={1.5} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Group wrap="nowrap">
                            <Text size="sm">Are you sure you want to delete this contact?</Text>
                            <Button.Group>
                                <Button variant="subtle" size="xs" onClick={() => setDeleteContactPopoverOpen(false)}><IconX /></Button>
                                <Button variant="subtle" color="red" size="xs" onClick={() => onDelete(position)}><IconCheck /></Button>
                            </Button.Group>
                        </Group>
                    </Popover.Dropdown>
                </Popover>
            </Table.Td>
        </Table.Tr>
    );
};

export default ContactItem;