import { useEffect, useState } from 'react';
import { Button, Group, TextInput, Select} from '@mantine/core';
import { saveUserSettings, reAuthenticateUser } from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';

const UserGeneralSettings = ({ user }) => {
    const { resetAllStatistics, setUsers, userId, userRole, setUserRole } = useUserState();
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [role, setRole] = useState("");
    const [roleError, setRoleError] = useState("");

    const handleSave = async () => {
        setNameError("");
        setEmailError("");
        setRoleError("");

        if (!name) {
            setNameError("Please enter a name.");
            return;
        } else if (!email) {
            setEmailError("Please enter an email address.");
            return;
        } else if (!role) {
            setRoleError("Please select a role.");
            return;
        } else {
            const response = await saveUserSettings(user.id, {
                name,
                email,
                role,
            });
            const reAuthResponse = await reAuthenticateUser();

            if (response.success && reAuthResponse.success) {
                resetAllStatistics();
                setUsers(prevState => {
                    let newUsers = [...prevState];
                    return newUsers.map(u => {
                        if (u.id === user.id) {
                            return {
                                ...u,
                                name,
                                email,
                                role,
                            };
                        } else {
                            return u;
                        }
                    });
                });

                if(user.id === userId) {
                    setUserRole(role);
                }

                showSuccessNotification("User settings saved successfully.");
            } else {
                showUnexpectedErrorNotification("There was an error saving the user settings. Please try again.");
            }
        }
    };

    useEffect(() => {
        if (user?.id) {
            setName(user.name);
            setEmail(user.email);
            setRole(user.role);
        }
    }, [user?.id]);

    return (
        <div className="d-flex flex-column flex-grow-1">
            <TextInput
                label="Name"
                value={name}
                onChange={e => setName(e.target.value)}
                error={nameError}
                className="tif-mb-20"
            />

            <TextInput
                label="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={emailError}
                className="tif-mb-20"
            />

            {
                userRole === "ops" ?
                    <Select
                        label="Role"
                        placeholder="Role"
                        data={[
                            { value: 'ops', label: 'Operations' },
                            { value: 'dev', label: 'Developer' },
                            { value: 'design', label: 'Designer' },
                        ]}
                        value={role}
                        onChange={(_value, option) => setRole(option.value)}
                        error={roleError}
                        className="tif-mb-20"
                    />
                :
                    <></>
            }

            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline">
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default UserGeneralSettings;