import { useState, useEffect } from "react";
import { useUserState } from "./UserStateContext";
import { Text } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";

const UpdateStatus = () => {
    const { setRefresh } = useUserState();

    const [timeSinceRefresh, setTimeSinceRefresh] = useState(sessionStorage.getItem('elapsedTime') || 0);

    useEffect(() => {
        if (!sessionStorage.getItem('elapsedTime')) {
            sessionStorage.setItem('elapsedTime', '0');
        }
    
        const updateElapsedTime = () => {
            let elapsedTime = parseInt(sessionStorage.getItem('elapsedTime'), 10);
            elapsedTime += 5;
            setTimeSinceRefresh(elapsedTime);
            sessionStorage.setItem('elapsedTime', elapsedTime.toString());
        };
    
        const intervalId = setInterval(updateElapsedTime, 5000);
    
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        timeSinceRefresh >= 300 && setRefresh(true);
    }, [timeSinceRefresh]);

    return (
        <Text size="sm" align="end" mb="10px">
            Updated { timeSinceRefresh < 60 ? "just now" : ` ${ Math.floor(timeSinceRefresh / 60) } minute${ Math.floor(timeSinceRefresh / 60) > 1 ? "s" : "" } ago` }
            <IconRefresh className="icon tif-mr-10 align-bottom tif-ml-10" size="1.4rem" stroke={1.5} />
        </Text>
    )
};

export default UpdateStatus;