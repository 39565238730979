import { Link } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../data/dataApi";
import LoginScreen from '../../views/components/LoginScreen';
import { Text, Center, Box, PasswordInput, Progress, Group, Stack, Button } from "@mantine/core";
import { IconCheck, IconX, IconArrowLeft } from "@tabler/icons-react";

const NewPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState();
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newConfirmPasswordError, setNewConfirmPasswordError] = useState();
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [error, setError] = useState();

    const { key } = useParams();

    const requirements = [
        { re: /.{8,}/, label: 'At least 8 characters long' },
        { re: /[A-Z]/, label: 'Includes uppercase letter' },
        { re: /[0-9]/, label: 'Includes number' },
    ];

    const getStrength = password => {
        let multiplier = password.length > 5 ? 0 : 1;

        requirements.forEach((requirement) => {
            if (!requirement.re.test(password)) {
                multiplier += 1;
            }
        });

        return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
    };

    const strength = getStrength(newPassword);

    const PasswordRequirement = ({ meets, label }) => {
        return (
            <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm">
                <Center inline>
                    {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
                    <Box ml={7}>{label}</Box>
                </Center>
            </Text>
        );
    };

    const bars = Array(requirements.length)
        .fill(0)
        .map((_, index) => (
            <Progress
                styles={{ section: { transitionDuration: '0ms' } }}
                value={
                    newPassword.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / requirements.length) * 100 ? 100 : 0
                }
                color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
                key={index}
                size={requirements.length}
            />
        ));

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(newPassword)} />
    ));

    const handleChangePassword = async () => {
        setNewPasswordError(false);
        setNewConfirmPasswordError("");
        setError("");

        if (!newPassword || strength !== 100) {
            setNewPasswordError(true);
        }

        if (!newConfirmPassword || newPassword !== newConfirmPassword) {
            setNewConfirmPasswordError("Passwords do not match.");
        } else {
            const response = await resetPassword(key, newPassword);

            if (response.error) {
                setError(response.error.message);
            } else if (response.success) {
                setPasswordChanged(true);
            }
        }
    };

    return (
        <LoginScreen>
            <Stack justify="center" gap="0" className="h-100">
                {
                    !passwordChanged ?
                        <>
                            <h1 className="tif-mb-md-15">Reset Password</h1>
                            <Text mb="50">Choose a new password for your account.</Text>

                            <PasswordInput
                                value={newPassword}
                                labelProps={{ mb: 10 }}
                                onChange={e => setNewPassword(e.target.value)}
                                placeholder="New password"
                                label="New Password"
                                required
                                error={newPasswordError}
                            />

                            <Group gap={5} grow mt="xs" mb="md">
                                {bars}
                            </Group>

                            {checks}

                            <PasswordInput
                                value={newConfirmPassword}
                                labelProps={{ mb: 10 }}
                                onChange={e => setNewConfirmPassword(e.target.value)}
                                placeholder="New password"
                                label="Confirm New Password"
                                required
                                error={newConfirmPasswordError}
                                mt="20"
                            />

                            {error && <p className="error-text">{error}</p>}

                            <Group justify="space-between" mt="lg" className="controls">
                                <Link to="/" className="control">
                                    <Center inline c="dimmed">
                                        <IconArrowLeft style={{ width: "12px", height: "12px" }} stroke={1.5} />
                                        <Box ml={5} fz="sm">Back to the login page</Box>
                                    </Center>
                                </Link>
                                <Button className="control" onClick={handleChangePassword}>Reset password</Button>
                            </Group>
                        </>
                        :
                        <>
                            <h1 className="tif-mb-md-15">Password reset successfully</h1>
                            <Text mb="50">Log in to your account with your new password.</Text>

                            <Link to="/" className="primary-btn">
                                <Center inline>
                                    <IconArrowLeft style={{ width: "16px", height: "16px" }} stroke={1.5} />
                                    <Box ml={5} fz="md">Login</Box>
                                </Center>
                            </Link>
                        </>
                }
            </Stack>
        </LoginScreen>
    );
};

export default NewPassword;