import {
    Group,
    Avatar,
    Badge,
    Tooltip,
    Text,
    Menu
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import {
    getTempoUserWorklogs,
    getJiraIssues,
    getUserTicketCounts,
    getUserOpenTickets,
    getUserInProgressTickets,
    getUserCodeReviewTickets,
    getUserCustomerReviewTickets,
    getUserDoneTickets,
    getUserEmptyTimeEstimateTickets,
    getUserWhatsHotTickets,
    getUserHighPriorityTickets,
    getUserInternalReviewTickets
} from '../data/dataApi';
import { IconExternalLink } from '@tabler/icons-react';

const currentYear = new Date().getFullYear();
export const holidays = [
    `01/01/${currentYear}`,
    `05/27/${currentYear}`,
    `07/04/${currentYear}`,
    `09/02/${currentYear}`,
    `11/28/${currentYear}`,
    `11/29/${currentYear}`,
    `12/24/${currentYear}`,
    `12/25/${currentYear}`
];

export const validateEmail = (email, setEmailError) => {
    if (!email) {
        setEmailError("Please enter your email address.");
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
        setEmailError("The email address you entered is not valid. Please try again.");
    } else {
        return "success";
    }
};

export const validatePassword = (password, setPasswordError) => {
    if (!password) {
        setPasswordError("Please enter your password.");
    } else {
        return "success";
    }
};

export const roles = {
    ops: "Operations",
    design: "Designer",
    dev: "Developer",
};

export const formatDate = (timestamp) => {
    const date = new Date(new Date(timestamp).getTime() - (new Date().getTimezoneOffset() * 60000));
    const offsetDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    return offsetDate;
};

export const fileTypes = [
    {
        id: "product",
        name: "Product",
    },
    {
        id: "application",
        name: "Application",
    },
    {
        id: "gelest",
        name: "Gelest",
    }
];

export const priorityColors = {
    "Highest": "#c92a2a",
    "High": "#ff922b",
    "Medium": "gray",
    "Low": "#a5d8ff",
    "Lowest": "#339af0",
};

export const listTickets = (list, isAccount, disableAvatar, noLimit) => {
    if (list?.length) {

        if (isAccount) {
            list = list.filter(item => item.avatarUrl && item.name);
        }

        list = noLimit ? list : list.slice(0, 10);

        return list.map((item, index) => (
            <Group gap="10px" className="flex-nowrap tif-mb-10 w-100" key={index} style={{ cursor: "pointer" }}>
                {
                    !disableAvatar ?
                        <Avatar size={24} src={!isAccount ? item.fields.assignee?.avatarUrls['48x48'] : item.avatarUrl} radius={30} />
                        :
                        <></>
                }
                {
                    item.fields?.summary ?
                            <Menu withArrow>
                                <Menu.Target>
                                    <Group gap="10px" className="truncate-wrapper flex-nowrap">
                                        <Badge color={!isAccount ? priorityColors[item.fields.priority?.name] || "gray" : "gray"} className={`position-relative flex-shrink-0 ${(!isAccount && item.fields.priority?.name === "Highest") ? "pulse-animation" : ""}`} variant="light">
                                            {!isAccount ? item.key : item.name}
                                        </Badge>
                                        <Tooltip.Floating label={item.fields.summary}>
                                            <div className="truncate-wrapper">
                                                <Text fz="sm" style={{ minWidth: 0 }} truncate="end" color={(!isAccount && item.fields.priority?.name !== "Medium") ? priorityColors[item.fields.priority?.name] : ""} className="position-relative">
                                                    {item.fields.summary}
                                                </Text>
                                            </div>
                                        </Tooltip.Floating>
                                    </Group>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {/* <Menu.Item
                                            leftSection={<IconClock style={{ width: 14, height: 14 }} />}
                                        >
                                            Track time
                                        </Menu.Item> */}
                                    <Menu.Item
                                        leftSection={<IconExternalLink style={{ width: 14, height: 14 }} />}
                                        component="a"
                                        href={`https://thinkitfirst.atlassian.net/browse/${item.key}`}
                                        target="_blank"
                                    >
                                        Open issue
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        :
                            <Badge color={!isAccount ? priorityColors[item.fields.priority?.name] || "gray" : "gray"} className="position-relative" variant="light">
                                {!isAccount ? item.key : item.name}
                                {!isAccount ? <Link to={`https://thinkitfirst.atlassian.net/browse/${item.key}`} className="stretched-link" target="_blank"></Link> : <></>}
                            </Badge>
                }
            </Group>
        ));
    } else {
        return <p>0 {!isAccount ? "tickets" : "accounts"}.</p>
    }
};

export const today = new Date();
export const monthNum = today.getMonth();
export const year = today.getFullYear();
export const firstMonthDay = new Date(year, monthNum, 1);
export const lastMonthDay = new Date(year, monthNum + 1, 0);

export const getNetworkDays = (startDate = firstMonthDay, endDate = lastMonthDay) => {
    if (endDate > startDate) {
        let days = Math.ceil((endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) / (86400 * 1000));
        const weeks = Math.floor(Math.ceil((endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) / (86400 * 1000)) / 7);

        days = days - (weeks * 2);
        days = startDate.getDay() - endDate.getDay() > 1 ? days - 2 : days;
        days = startDate.getDay() === 0 && endDate.getDay() !== 6 ? days - 1 : days;
        days = endDate.getDay() === 6 && startDate.getDay() !== 0 ? days - 1 : days;

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();
            const formattedDate = currentDate.toISOString().split('T')[0];

            const [year, month, day] = formattedDate.split('-');
            const formattedHolidayDate = `${month}/${day}/${year}`;

            if (dayOfWeek !== 0 && dayOfWeek !== 6 && holidays.includes(formattedHolidayDate)) {
                days--;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return days;
    }

    return null;
};

export const showUnexpectedErrorNotification = () => {
    notifications.show({
        color: 'red',
        title: 'Bummer!',
        message: 'Something went wrong',
        zIndex: 999,
        autoClose: false,
    });
};

export const showSuccessNotification = (message = "Form submitted successfully") => {
    notifications.show({
        color: 'green',
        title: 'Success!',
        message,
        zIndex: 999,
        autoClose: 5000,
    });
};

export const generateDateArray = (startDate, endDate) => {
    let dateArray = [];

    if (startDate && endDate) {
        let start = new Date(startDate);
        let end = new Date(endDate);

        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            if (d.getDay() !== 0 && d.getDay() !== 6) {
                let formattedDate = `${String(d.getMonth() + 1).padStart(2, '0')}/${String(d.getDate()).padStart(2, '0')}/${d.getFullYear()}`;
                dateArray.push(formattedDate);
            }
        }
    }

    return dateArray;
};

export const getCurrentMonthPTOHours = PTODates => {
    const currentMonthPtoDates = PTODates.filter(pto => {
        const ptoDate = new Date(pto.date);
        const currentDate = new Date();

        return ptoDate.getMonth() === currentDate.getMonth() &&
            ptoDate.getFullYear() === currentDate.getFullYear();
    });

    return currentMonthPtoDates.reduce((sum, pto) => sum + pto.hours, 0);
};

export const userSettingsLinks = [
    { label: 'General', link: '#general' },
    { label: 'Time', link: '#time', adminOnly: true },
    { label: 'Add Time Off', link: '#time-off', order: 2, adminOnly: true },
    { label: 'Existing Time Off', link: '#existing-time-off', order: 2, adminOnly: true },
    // { label: 'Security', link: '#security' },
];

export const filteredOutsideMonth = (datesOff, date) => {
    const currentDate = date || new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return datesOff
        .filter(curr => {
            const [month, day, year] = curr.date.split("/");
            return +year === currentYear && +month - 1 === currentMonth;
        });
};

export const filteredOutsideMonthHours = datesOff => {
    return filteredOutsideMonth(datesOff).reduce((acc, curr) => acc + curr.hours, 0);
};

export const formatMonthDay = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${month}/${day}`;
};

export const getEarliestDate = (dates) => {
    return dates.reduce((earliest, curr) => {
        const currDate = new Date(curr.date);
        return (!earliest || currDate < earliest) ? currDate : earliest;
    }, null);
};

export const getLatestDate = (dates) => {
    return dates.reduce((latest, curr) => {
        const currDate = new Date(curr.date);
        return (!latest || currDate > latest) ? currDate : latest;
    }, null);
};

export const handleUserStats = async (user, setUserStats, excludeAccounts) => {
    let billedTime = 0,
        yesterdayBillableTime = 0,
        yesterdayUnbilledTime = 0;

    const { jira_account_id } = user;

    const today = new Date();

    const getFirstDayOfMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    };

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    const from = getFirstDayOfMonth();
    const options = { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit' };
    const [month, day, year] = today.toLocaleDateString('en-US', options).split('/');
    const to = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedYesterday = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;

    const tempoUserWorklogs = await getTempoUserWorklogs(jira_account_id, from, to);
    const userTicketCounts = await getUserTicketCounts(jira_account_id, excludeAccounts);
    const userOpenTickets = await getUserOpenTickets(jira_account_id, excludeAccounts);
    const userInProgressTickets = await getUserInProgressTickets(jira_account_id, excludeAccounts);
    const userCodeReviewTickets = await getUserCodeReviewTickets(jira_account_id, excludeAccounts);
    const userInternalReviewTickets = await getUserInternalReviewTickets(jira_account_id, excludeAccounts);
    const userCustomerReviewTickets = await getUserCustomerReviewTickets(jira_account_id, excludeAccounts);
    const userDoneTickets = await getUserDoneTickets(jira_account_id, excludeAccounts);
    const tempoUserYesterdayWorklogs = await getTempoUserWorklogs(jira_account_id, formattedYesterday, formattedYesterday);

    const tempoWorklogs = tempoUserWorklogs.data?.results.filter(worklog => new Date(worklog.createdAt) >= twoWeeksAgo);
    const tempoWorklogIssues = tempoWorklogs?.map(result => result.issue.id);
    const issuesResponse = await getJiraIssues(tempoWorklogIssues, excludeAccounts);
    const userEmptyTimeEstimateTickets = await getUserEmptyTimeEstimateTickets(jira_account_id, excludeAccounts);
    const userWhatsHotTickets = await getUserWhatsHotTickets(jira_account_id, excludeAccounts);
    const userHighPriorityTickets = await getUserHighPriorityTickets(jira_account_id, excludeAccounts);

    const getDaysSince = (date, excludedDates = []) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const pastDate = new Date(date);
        let daysCount = 0;

        const daysOff = [
            ...holidays,
            ...excludedDates
        ];

        const formatDate = date => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        };

        while (pastDate < today) {
            if (pastDate.getDay() !== 0 && pastDate.getDay() !== 6 && !daysOff.includes(formatDate(pastDate))) {
                daysCount++;
            }
            pastDate.setDate(pastDate.getDate() + 1);
        }

        return daysCount;
    };

    tempoUserWorklogs.data.results.forEach(worklog => {
        billedTime += worklog.billableSeconds;
    });

    tempoUserYesterdayWorklogs.data.results.forEach(worklog => {
        yesterdayBillableTime += worklog.billableSeconds;

        if (!worklog.billableSeconds) {
            yesterdayUnbilledTime += worklog.timeSpentSeconds;
        }
    });

    const seen = new Set();
    const filteredIssues = issuesResponse.data.issues.filter(issue => {
        const duplicate = seen.has(issue.fields.project.name);
        seen.add(issue.fields.project.name);
        return !duplicate;
    });

    const groupedIssues = tempoWorklogs?.reduce((acc, worklog) => {
        const value = worklog.attributes.values[0].value;
        const existingGroup = acc.find(group => group.value === value);

        if (existingGroup) {
            existingGroup.ids.push(worklog.issue.id);
            existingGroup.billableSeconds += worklog.billableSeconds;
        } else {
            acc.push({
                ids: [worklog.issue.id],
                value: value,
                billableSeconds: worklog.billableSeconds,
            });
        }

        return acc;
    }, []).sort((a, b) => b.billableSeconds - a.billableSeconds);

    const updatedGroupedIssues = groupedIssues.map(group => {
        const matchingIssue = filteredIssues.find(issue => group.ids.includes(Number(issue.id)));

        if (matchingIssue) {
            return {
                ...group,
                name: matchingIssue.fields.project.name,
                avatarUrl: matchingIssue.fields.project.avatarUrls['48x48'],
            };
        }

        return group;
    });

    setUserStats(prevState => {
        const newUserStats = {
            ...user,
            time: {
                billed: (billedTime / 3600).toFixed(2),
                previousLoggedDays: tempoWorklogs?.length ? getDaysSince(tempoWorklogs[tempoWorklogs?.length - 1].createdAt, user.pto?.map(pto => pto.date)) : `${new Date().getDate()}+`,
                yesterdayBillable: (yesterdayBillableTime / 3600).toFixed(2),
                yesterdayUnbillable: (yesterdayUnbilledTime / 3600).toFixed(2),
            },
            ticketCounts: {
                open: userTicketCounts.data[0].total,
                atRisk: userTicketCounts.data[1].total,
            },
            recentClients: updatedGroupedIssues,
            openTickets: userOpenTickets.data,
            inProgressTickets: userInProgressTickets.data,
            codeReviewTickets: userCodeReviewTickets.data,
            internalReviewTickets: userInternalReviewTickets.data,
            customerReviewTickets: userCustomerReviewTickets.data,
            doneTickets: userDoneTickets.data,
            emptyTimeEstimateTickets: userEmptyTimeEstimateTickets.data,
            whatsHotTickets: userWhatsHotTickets.data,
            highPriorityTickets: userHighPriorityTickets.data,
        };

        const existingUser = prevState.find(user => user.id === newUserStats.id);

        if (existingUser) {
            return prevState.map(user => user.id === newUserStats.id ? newUserStats : user);
        } else {
            return [...prevState, newUserStats];
        }
    });
}

export const projectTypes = [
    "Project",
    "Targets",
    "Maintenance",
    "Ad Hoc"
];

export const toIdentifier = str => {
    return str
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .replace(/\s+/g, '_')
        .toLowerCase();
};

export const formatUserPTOMessage = (user, excludeName) => {
    if (user.pto.length === 1) {
        const message = `on ${formatMonthDay(new Date(user.pto[0].date))}`;
        return !excludeName ? `${user.name} is off ${message}` : `Off ${message}`;
    }

    const sortedPTO = user.pto.map(pto => new Date(pto.date)).sort((a, b) => a - b);

    const groups = [];
    let group = [sortedPTO[0]];

    for (let i = 1; i < sortedPTO.length; i++) {
        const currentDate = sortedPTO[i];
        const previousDate = sortedPTO[i - 1];
        const oneDayInMillis = 24 * 60 * 60 * 1000;

        if (currentDate - previousDate === oneDayInMillis) {
            group.push(currentDate);
        } else {
            groups.push(group);
            group = [currentDate];
        }
    }
    groups.push(group);

    const formattedGroups = groups.map(group => {
        if (group.length === 1) {
            return formatMonthDay(group[0]);
        } else {
            return `from ${formatMonthDay(group[0])} to ${formatMonthDay(group[group.length - 1])}`;
        }
    });

    const formattedText = formattedGroups.length > 1 
        ? `${formattedGroups.slice(0, -1).join(', ')}, and ${formattedGroups.slice(-1)}` 
        : formattedGroups[0];

    return !excludeName ? `${user.name} is off ${formattedText}` : `Off ${formattedText}`;
};

export const isCurrentMonthAndYear = date => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    const nowYear = date.getFullYear();
    const nowMonth = date.getMonth();

    return (nowYear === currentYear) && (nowMonth === currentMonth);
};

export const clientsSettingsLinks = [
    { label: 'Global Field Groups', link: '#global-field-groups' },
    { label: 'Project Field Groups', link: '#project-field-groups' },
    { label: 'Targets Field Groups', link: '#targets-field-groups' },
    { label: 'Maintenance Field Groups', link: '#maintenance-field-groups' },
    { label: 'Ad Hoc Field Groups', link: '#ad-hoc-field-groups' },
];

export const fieldTypes = [
    "Address",
    "Date Picker",
    "Email",
    "Number",
    "Password",
    "Phone",
    "Text",
    "URL",
    "Yes/No",
];

export const reformatClientDetails = clientDetails => {
    const restructuredClientDetails = [];

    clientDetails.forEach(item => {
        const { field_group_id, field_group_name, field_group_fields, field_group_global, ...rest } = item;

        const existingGroup = restructuredClientDetails.find(group =>
            group.field_group_id === field_group_id &&
            group.field_group_name === field_group_name &&
            JSON.stringify(group.field_group_fields) === JSON.stringify(field_group_fields)
        );

        if (existingGroup) {
            existingGroup.fields_values.push(rest);
        } else {
            restructuredClientDetails.push({
                field_group_id,
                field_group_name,
                field_group_fields,
                field_group_global,
                fields_values: [rest]
            });
        }
    });

    return restructuredClientDetails;
};

export const hosts = [
    'Flywheel',
];

export const extractTextFromContent = content => {
    let text = '';

    content.forEach(item => {
        if (item.type === 'text' && item.text) {
            text += item.text + ' ';
        }

        if (item.content) {
            text += extractTextFromContent(item.content) + ' ';
        }

        if (item.type === 'inlineCard' && item.attrs && item.attrs.url) {
            text += item.attrs.url + ' ';
        }
    });

    return text.trim();
};