import { SegmentedControl, VisuallyHidden } from "@mantine/core";
import { IconLayoutGrid, IconList } from "@tabler/icons-react";
import { useEffect } from "react";

const ViewModeControl = ({ viewMode, onChange, id }) => {
    const iconProps = {
        style: { width: "20px", height: "20px", display: 'block' },
        stroke: 1.5,
    };

    useEffect(() => {
        if(id) {
            localStorage.setItem(`${id}_view_mode`, viewMode);
        }
    }, [viewMode]);
    
    return(
        <SegmentedControl
            size="xs"
            value={viewMode}
            onChange={onChange}
            data={[
                {
                    value: 'grid-view',
                    label: (
                        <>
                            <IconLayoutGrid {...iconProps} />
                            <VisuallyHidden>Grid View</VisuallyHidden>
                        </>
                    ),
                },
                {
                    value: 'list-view',
                    label: (
                        <>
                            <IconList {...iconProps} />
                            <VisuallyHidden>List View</VisuallyHidden>
                        </>
                    ),
                },
            ]}
        />
    );
};

export default ViewModeControl;