import { useState } from 'react';
import { Modal, Button, Group, PasswordInput, Text, Center, Box, Progress } from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons-react';
import { saveUserSettings } from '../../data/dataApi';

const ChangeUserPasswordModal = ({ opened, close, user }) => {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");

    const requirements = [
        { re: /.{8,}/, label: 'At least 8 characters long' },
        { re: /[A-Z]/, label: 'Includes uppercase letter' },
        { re: /[0-9]/, label: 'Includes number' },
    ];

    const getStrength = password => {
        let multiplier = password.length > 5 ? 0 : 1;

        requirements.forEach((requirement) => {
            if (!requirement.re.test(password)) {
                multiplier += 1;
            }
        });

        return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
    };

    const strength = getStrength(password);

    const PasswordRequirement = ({ meets, label }) => {
        return (
            <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm">
                <Center inline>
                    {meets ? <IconCheck size="0.9rem" stroke={1.5} /> : <IconX size="0.9rem" stroke={1.5} />}
                    <Box ml={7}>{label}</Box>
                </Center>
            </Text>
        );
    };

    const bars = Array(requirements.length)
        .fill(0)
        .map((_, index) => (
            <Progress
                styles={{ section: { transitionDuration: '0ms' } }}
                value={
                    password.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / requirements.length) * 100 ? 100 : 0
                }
                color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
                key={index}
                size={requirements.length}
            />
        ));

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(password)} />
    ));

    const handleChangePassword = async () => {
        setPasswordError("");
        setNewPasswordError(false);

        if (!password || strength !== 100) {
            setNewPasswordError(true);
        }

        // if (!newPassword || password !== newPassword) {
        //     setNewPasswordError("Passwords do not match.");
        // } else {
        //     const response = await resetPassword(key, password);

        //     if (response.error) {
        //         setError(response.error.message);
        //     } else if (response.success) {
        //         setPasswordChanged(true);
        //     }
        // }
    };
     
    return (
        <Modal opened={opened} onClose={close} title="Change Password" overlayProps={{ blur: 3 }}>
            {/* <PasswordInput
                value={password}
                labelProps={{ mb: 10 }}
                onChange={e => setNewPassword(e.target.value)}
                placeholder="New password"
                label="New Password"
                required
                error={newPasswordError}
            />

            <Group gap={5} grow mt="xs" mb="md">
                {bars}
            </Group>
            
            {checks}

            <PasswordInput
                value={newConfirmPassword}
                labelProps={{ mb: 10 }}
                onChange={e => setNewConfirmPassword(e.target.value)}
                placeholder="New password"
                label="Confirm New Password"
                required
                error={newConfirmPasswordError}
                mt="20"
            /> */}
            
            <Group justify="right">
                <Button onClick={handleChangePassword} variant="outline">
                    Save
                </Button>
            </Group>
        </Modal>
    );
};

export default ChangeUserPasswordModal;