import { useEffect, useState } from 'react';
import { NumberInput, Button, Group, Table, Text } from '@mantine/core';
import { updateUserTimeOffSettings } from '../../data/dataApi';
import { generateDateArray, getCurrentMonthPTOHours, getNetworkDays, showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';
import { useUserState } from './UserStateContext';
import { DateInput } from '@mantine/dates';
import { filteredOutsideMonthHours } from '../helpers';

const UserTimeOffSettings = ({ user, data, onSuccessChangeTab, onModalUserUpdate }) => {
    const { resetAllStatistics, setUsers } = useUserState();
    const { dailyBillableGoal, PTOStartDate, setPTOStartDate, PTOEndDate, setPTOEndDate } = data;
    const [PTOStartDateError, setPTOStartDateError] = useState("");
    const [PTOEndDateError, setPTOEndDateError] = useState("");
    const [datesOff, setDatesOff] = useState([]);
    const [hoursTotalError, setHoursTotalError] = useState(null);

    let maxHoursAvailable = getNetworkDays() * dailyBillableGoal;

    if(user.pto) {
        maxHoursAvailable -= getCurrentMonthPTOHours(user.pto);
        maxHoursAvailable -= filteredOutsideMonthHours(datesOff);
    }

    const handleSave = async () => {
        setPTOStartDateError("");
        setPTOEndDateError("");
        setHoursTotalError(null);

        if (!PTOStartDate && PTOEndDate) {
            setPTOStartDateError("Please enter a date.");
            return;
        } else if (!PTOEndDate && PTOStartDate) {
            setPTOEndDateError("Please enter a date.");
            return;
        } else if(datesOff.find(date => !date.hours)) {
            setHoursTotalError({ index: datesOff.findIndex(date => !date.hours), message: "Please enter a number greater than 0." });
            return;
        } else if(datesOff.find(date => Number(date.hours) > 24)) {
            setHoursTotalError({ index: datesOff.findIndex(date => Number(date.hours) > 24), message: "Please enter a number less than or equal to 24." });
            return;
        }

        const response = await updateUserTimeOffSettings(user.id, datesOff);

        if (response.success) {
            setUsers(prevState => {
                let newUsers = [...prevState];
                return newUsers.map(u => {
                    if (u.id === user.id) {
                        return {
                            ...u,
                            pto: u.pto ? [...u.pto, ...datesOff] : datesOff,
                        };
                    } else {
                        return u;
                    }
                });
            });
            onModalUserUpdate(prevState => ({
                ...prevState,
                pto: prevState.pto ? [...prevState.pto, ...datesOff] : datesOff,
            }));
            resetAllStatistics();
            onSuccessChangeTab();
            showSuccessNotification("User time off settings saved successfully.");
        } else {
            showUnexpectedErrorNotification("There was an error saving the user time settings. Please try again.");
        }
    };

    const handleDateTotalHoursChange = (val, index) => {
        setDatesOff(prevState => {
            let newDatesOff = [...prevState];
            newDatesOff[index].hours = val;
            return newDatesOff;
        });
    };

    useEffect(() => {
        if (!PTOEndDate || PTOStartDate > PTOEndDate) {
            setPTOEndDate(PTOStartDate);
        } else if (!PTOStartDate) {
            setPTOEndDate(null);
        }
    }, [PTOStartDate]);

    useEffect(() => {
        if (PTOEndDate) {
            setDatesOff(generateDateArray(PTOStartDate, PTOEndDate).map(date => ({
                date,
                hours: 8,
            })));
        }
    }, [PTOStartDate, PTOEndDate]);

    return (
        <div className="d-flex flex-column flex-grow-1">
            <Group grow={1} className="tif-mb-20" align="flex-start">
                <DateInput
                    value={PTOStartDate}
                    onChange={setPTOStartDate}
                    label="PTO Start Date"
                    placeholder="PTO Start Date"
                    valueFormat="MM/DD/YYYY"
                    clearable
                    error={PTOStartDateError}
                />

                <DateInput
                    value={PTOEndDate}
                    onChange={setPTOEndDate}
                    label="PTO End Date"
                    placeholder="PTO End Date"
                    valueFormat="MM/DD/YYYY"
                    minDate={PTOStartDate}
                    clearable
                    error={PTOEndDateError}
                />
            </Group>

            <Table className="tif-mb-20">
                <Table.Thead>
                    <Table.Tr className="border-0">
                        <Table.Th className="tif-px-0">Hours Available This Month</Table.Th>
                        <Table.Td className="text-end tif-px-0">{ maxHoursAvailable }</Table.Td>
                    </Table.Tr>
                </Table.Thead>
            </Table>

            {
                datesOff.map(({ date, hours }, index) =>
                    <Group className="date-total-hours tif-mb-15" key={index} align="flex-end" justify="space-between">
                        <Text fz="md" fw={500}>{date}</Text>
                        <NumberInput
                            label="Hours Off"
                            value={hours}
                            allowNegative={false}
                            variant="filled"
                            onChange={val => handleDateTotalHoursChange(val, index)}
                            error={hoursTotalError?.index === index ? hoursTotalError.message : null}
                        />
                    </Group>
                )
            }

            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline">
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default UserTimeOffSettings;