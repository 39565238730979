import { useEffect, useState } from 'react';
import { Box, Button, Group } from '@mantine/core';
import { getFieldGroup, saveFieldGroupValues, deleteField } from '../../data/dataApi';
import { showSuccessNotification, showUnexpectedErrorNotification, toIdentifier } from '../helpers';
import Field from './Field';
import { IconTrash } from '@tabler/icons-react';

const FieldGroupSettings = ({ clientId, id, link, onSave, editFieldsMode }) => {
    const [fields, setFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [fieldGroupId, setFieldGroupId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        const response = await saveFieldGroupValues(clientId, fieldGroupId, fieldValues);

        if(response.success) {
            showSuccessNotification("Field group values saved successfully");
        } else {
            showUnexpectedErrorNotification();
        }

        onSave && onSave();

        setLoading(false);
    };

    const handleDeleteField = async name => {
        const response = await deleteField(name, fields.filter(field => field.name !== name), fieldGroupId);

        if(response.success) {
            setFields(prevState => prevState.filter(field => field.name !== name));
            showSuccessNotification("Field deleted successfully");
        } else {
            showUnexpectedErrorNotification();
        }
    };

    useEffect(() => {
        (async () => {
            const response = await getFieldGroup(clientId, id);
            
             if(response.success) {
                if(response.data.fieldGroups.length) {
                    setFields(response.data.fieldGroups.map(field => field.fields)[0]);
                    setFieldGroupId(response.data.fieldGroups[0].id);
                    setFieldValues(response.data.fieldValues[0]);
                }   
            }
        })();
    }, [link]);

    return (
        <div className="d-flex flex-column flex-grow-1">
            {
                fields.map((field, index) => 
                    <Group align="center">
                        <Box className="flex-grow-1">
                            <Field field={field} value={fieldValues.find(item => item.name === toIdentifier(field.name))?.value} onChange={setFieldValues} key={index} />
                        </Box>
                        {
                            editFieldsMode &&
                                <Button variant="subtle" color="red" onClick={() => handleDeleteField(field.name, fieldGroupId)}><IconTrash size="20px" /></Button>
                        }
                    </Group>
                )
            }
                
            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline" loading={loading}>
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default FieldGroupSettings;