import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkUser } from '../../data/dataApi';
import { useUserState } from './UserStateContext';

const RouteHandler = ({ element, requireAuth, admin, excludeAuth, user }) => {
    const [loading, setLoading] = useState(true);

    const { setLoggedIn } = useUserState();

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        (async () => {
            if(!excludeAuth) {
                const response = await checkUser();

                setLoggedIn(!!response.id);

                if(response.id && !requireAuth || (admin && response.role !== "ops") || (id && user && response.id !== Number(id))) {
                    navigate("/home", { replace: true });
                } else if(!response.id && requireAuth) {
                    navigate("/", { replace: true });
                }
            }
            
            setLoading(false);
        })();
    }, [navigate]);

    if(loading) {
        return <></>;
    } else {
        return element;
    }
};

export default RouteHandler;