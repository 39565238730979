import { Modal, Button, Text, Group } from "@mantine/core";

const ConfirmationModal = ({ opened, close, message, onAccept }) => {
    return (
        <Modal opened={opened} onClose={close} title="Delete Time Off" zIndex="999">
            <Text mb="20px">{ message }</Text>
            <Group justify="flex-end">
                <Button onClick={close} variant="default">Cancel</Button>
                <Button color="red" onClick={onAccept}>Delete</Button>
            </Group>
        </Modal>
    )
};

export default ConfirmationModal;