import { TextInput, Group, Anchor, Center, Box, Button, Stack, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { validateEmail } from "../../views/helpers";
import { sendPasswordResetEmail } from "../../data/dataApi";
import LoginScreen from "../../views/components/LoginScreen";

const ResetPassword = () => {
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState();
    const [emailSent, setEmailSent] = useState(false);
    const [emailResent, setEmailResent] = useState(false);

    const emailRef = useRef();

    const handleSendEmail = async () => {
        setEmailError("");
        const { value } = emailRef.current;
        setEmail(value);

        const response = await sendPasswordResetEmail(value);

        if (validateEmail(value, setEmailError) === "success") {
            if (response.error) {
                setEmailError(response.error.message);
            } else if (response.success) {
                setEmailSent(true);
            }
        }
    };

    const handleResendEmail = async () => {
        const response = await sendPasswordResetEmail(email);

        if (response.success) {
            setEmailResent(true);
        }
    };

    return (
        <LoginScreen>
            <Stack justify="center" gap="0" className="h-100">
                {
                    !emailSent ?
                        <>
                            <h1 className="tif-mb-15">Forgot password?</h1>
                            <Text mb="50" fz="sm" c="dimmed">Enter the email you used to create your account so we can send you a link for reseting your password.</Text>

                            <TextInput label="Your email" placeholder="me@thinkitfirst.com" required error={emailError} ref={emailRef} />

                            <Group justify="space-between" mt="lg" className="controls">
                                <Link to="/" className="control">
                                    <Center inline c="dimmed">
                                        <IconArrowLeft style={{ width: "12px", height: "12px" }} stroke={1.5} />
                                        <Box ml={5} fz="sm">Back to the login page</Box>
                                    </Center>
                                </Link>
                                <Button className="control" onClick={ handleSendEmail }>Reset password</Button>
                            </Group>
                        </>
                        :
                        <>
                            <h1 className="tif-mb-15">Check your email</h1>
                            <Text mb="35">We have sent an email with password reset information to {email}</Text>

                            <Text fz="sm" mb="15" className="text-center">Didn't receive the email? Check spam folder or</Text>
                            <Button mb="15" disabled={emailResent} onClick={handleResendEmail}>{ emailResent ? "Sent!" : "Resend Email" }</Button>
                            <Link to="/" className="control">
                                <Center inline c="dimmed">
                                    <IconArrowLeft style={{ width: "12px", height: "12px" }} stroke={1.5} />
                                    <Box ml={5} fz="sm">Back to the login page</Box>
                                </Center>
                            </Link>
                        </>
                }
            </Stack>
        </LoginScreen>
    );
};

export default ResetPassword;