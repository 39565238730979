import { Group, TextInput, Select, Button, useComputedColorScheme } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { fieldTypes } from "../helpers";

const FieldGroup = ({ field, onChange, position }) => {
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    return (
        <Group className="tif-mb-15">
            <TextInput
                placeholder="Field Name"
                className="flex-grow-1"
                value={field.name}
                onChange={e => onChange(prevState => prevState.map((f, i) => i === position ? { ...f, name: e.target.value } : f))}
            />
            <Select
                placeholder="Field Type"
                data={fieldTypes}
                className="flex-grow-1"
                value={field.type}
                onChange={value => onChange(prevState => prevState.map((f, i) => i === position ? { ...f, type: value } : f))}
                searchable
            />
            <Button variant="transparent" color="white" px="0" onClick={() => onChange(prevState => prevState.filter((_, i) => i !== position))}><IconX color={computedColorScheme === "dark" ? "white" : "black"} size={14} /></Button>
        </Group>
    );
};

export default FieldGroup;